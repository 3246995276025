import React from "react";
import { useSelector } from "react-redux";
import { IoMdCloseCircleOutline } from "react-icons/io";
export default function ModalTest({ isOpen3, onClose3 }) {
  const selector = useSelector((state) => state);
  const { users } = selector || {};
  const { reflist } = users || {};
  const { data } = reflist || [];

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center ${
        isOpen3 ? "" : "hidden"
      }`}
    >
      <div
        className="absolute inset-0 bg-gray-900 opacity-50"
        onClick={() => onClose3()}
      ></div>
      <div className="relative bg-white p-8 rounded-lg shadow-lg ">
        <div className="flex px-2 py-2 text-white text-lg gap-5 justify-between bg-[#0f9707] items-center ">
        <div className="">CreditRef History</div>
        <IoMdCloseCircleOutline className=" cursor-pointer text-2xl" onClick={() => onClose3()}/>
       
        </div>
        {data && data.length > 0 ? (
          <table className="table-auto w-full">
            <thead>
              <tr>
                {/* <th className="px-4 py-2">User ID</th> */}
                <th className="px-4 py-2">Credit Ref</th>
                <th className="px-4 py-2">Created At</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item._id}>
                  {/* <td className="border px-4 py-2">{item.userId}</td> */}
                  <td className="border px-4 py-2">{item.creditRef}</td>
                  <td className="border px-4 py-2">
                    {new Date(item.createdAt).toLocaleDateString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No data available</p>
        )}
     
      </div>
    </div>
  );
}
