import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gamesActions, reportActions } from '../../_actions';


import Loader from '../../components/Loader/Loader';


const BetHistoryJackPot = () => {
  let selector = useSelector(state => state)
  const dispatch = useDispatch();
  const today = new Date().toISOString().split('T')[0];
  let { games } = selector ? selector : {};
  let { allMarket, allUserList } = games ? games : {};
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  // console.log('options.....', options);
  const { winList, winTotal, loading } = useSelector(state => state.reports);
  const { userList, userTotal } = useSelector(state => state.reports);
  const { gamesList, gamesTotal } = useSelector(state => state.games);
  const [size, setSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [keyWord, setkeyWord] = useState('');
  const [fromDate, setfromDate] = useState('');
  const [Options, setOptions] = useState([])
  const [toDate, settoDate] = useState('');
  const [selecteddata, setselecteddata] = useState([])
  const [fullList, setfullList] = useState([])
  const [isOpenClose, setisopenClose] = useState('')
  const [isOpenCloseValue, setIsOpenCloseValue] = useState('')
  // console.log("isopenClose", isOpenClose);

  useEffect(() => {
    let gameReq = {
      "fromDate": fromDate,
      "toDate": toDate,
      "keyWord": keyWord,
      "sortBy": "",
      "sortOrder": "",
      "pageNo": pageNo,
      "size": size,
    }

    let gameReq2 = {
      "fromDate": fromDate,
      "toDate": toDate,
      "keyWord": keyWord,
      "sortBy": "",
      "sortOrder": "",
      "pageNo": 1,
      "size": 500
    }
    dispatch(gamesActions.getAllGames(gameReq2))
    // dispatch(reportActions.getNumberWiseMarketSum(gameReq));
    dispatch(gamesActions.getAllMarket())
    dispatch(gamesActions.getAllUser())



  }, []);


  useEffect(() => {
    if (allUserList) {
      const options = allUserList?.map((user) => ({
        label: user.userName,
        value: user._id
      }));
      // console.log(options);
      setOptions(options);
    }
  }, [allUserList])


  // const inputChangeCreate = (e) => {
  //   e.preventDefault();
  //   const selectedOption = e.target.options[e.target.selectedIndex];
  //   const isOpenClose = selectedOption?.getAttribute('data-isopenclose');
  //   // console.log("Options_________", isOpenClose)
  //   setisopenClose(isOpenClose)
  //   const { name, value } = e.target;
  //   setFieldsGame(prevState => ({ ...prevState, [name]: value }));
  //   setErrorsGame(prevState => ({ ...prevState, [name]: "" }));
  // };

  const inputChangeCreate = (e) => {
    e.preventDefault();
    const { name, value, tagName } = e.target;
  
    if (tagName === "SELECT") {
      const selectedOption = e.target.options[e.target.selectedIndex];
      const isOpenClose = selectedOption?.getAttribute('data-isopenclose') === "true"; 
      setisopenClose(isOpenClose);
    }
  
    // Update the state for the input change
    setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
    setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));
  };
  


  const handleOnChangeOpenClose = (e) => {
    console.log(e.target.value);
    setIsOpenCloseValue(e.target.value)

  }


  const createGameSubmit = async (e) => {


    e.preventDefault();
    if (handleValidationCreateGame()) {
      let filterData = await allMarket && Array.isArray(allMarket) && allMarket.filter((e) => e._id === fieldsGame?.userId2)
      let dataList = await filterData && filterData[0] && filterData[0]?.numList
      setfullList(dataList)
      let gameReq2 = {
        "date": fieldsGame?.date || "",
        "gameId": fieldsGame?.userId,
        "marketId": fieldsGame?.userId2,
        "isOpen": isOpenCloseValue ? isOpenCloseValue : false,
        isJackPot: true,
        brandGame: false,
      }
      dispatch(reportActions.getNumberWiseMarketSum(gameReq2));
    }
  };

  const handleValidationCreateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsGame.userId || fieldsGame.userId === "") {
      formIsValid = false;
      errors.userId = "Select Game Name";
    }

    // if (!fieldsGame.userId2 || fieldsGame.userId2 === "") {
    //   formIsValid = false;
    //   errors.userId2 = "Select Game Type";
    // }

    // if (!fieldsGame.todate || fieldsGame.todate === "") {
    //   formIsValid = false;
    //   errors.todate = "Select Date";
    // }

    if (!fieldsGame.date || fieldsGame.date === "") {
      formIsValid = false;
      errors.date = "Select Date";
    }

    setErrorsGame(errors);
    return formIsValid;
  };


  const handlePageClick = (data) => {
    console.log("data_page__page__page__page__page_???", data);
    let offset = Math.ceil(data.selected * size);
    setOffset(offset);
    setPageNo(data.selected + 1);
    let pageReq = {
      "date": fromDate,

    }

    dispatch(reportActions.getNumberWiseMarketSum(pageReq));
  }

  const handleInputSearch = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame(prevState => ({ ...prevState, [name]: value }));
    setErrorsGame(prevState => ({ ...prevState, [name]: "" }));
    let pageReq = {
      "type": "WITHDRAW",
      "keyWord": value,
      "pageNo": 1,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": fromDate,
      "toDate": toDate,
      "size": 100
    }
    // dispatch(walletActions.getPaymentListForAdmin(pageReq));
  };

  const handleInputSize = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame(prevState => ({ ...prevState, [name]: value }));
    setErrorsGame(prevState => ({ ...prevState, [name]: "" }));
    setSize(e.target.value)
    let pageReq = {
      // "type": "WITHDRAW",
      "keyWord": keyWord,
      "pageNo": 1,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": fromDate,
      "toDate": toDate,
      "size": value,
      isJackPot: true,
      brandGame: false,

    }
    dispatch(reportActions.getNumberWiseMarketSum(pageReq));
  };

  function isNestedArray(arr) {
    // Base case: if arr is not an array, it's not nested
    if (!Array.isArray(arr)) {
      return false;
    }

    // Check each element in the array
    for (let element of arr) {
      // If any element is an array, it's nested
      if (Array.isArray(element)) {
        return true;
      }
    }

    // If none of the elements are arrays, it's not nested
    return false;
  }

  const [selectedValues, setSelectedValues] = useState([]);
  let { reports } = selector ? selector : {}
  let { numberListData } = reports ? reports : []


  // console.log("numberListDatanumberListData", allMarket)



  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl space-y-10">

                      {/* <div className="px-6 py-4 text-left bg-white space-y-2 rounded-lg">
                        <div className="flex items-center justify-between">
                          <span className="font-semibold ">Filter List</span>
                        </div>
                        <div className='flex justify-between md:flex-nowrap gap-1  flex-wrap items-end  w-full '>
                          <div className="mt-1 shadow-sm w-full">
                            <label class="block text-gray-700 text-base mb-2" for="gamename">Date :</label>
                            <input className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none"
                              id="date" name="date" max={today} placeholder="Enter date" value={fieldsGame && fieldsGame["date"] ? fieldsGame["date"] : ""} type="date" onChange={inputChangeCreate} />
                            {errorsGame && errorsGame["date"] ?
                              <div className="text-red-600 invalid-feedback">
                                {errorsGame["date"]}
                              </div>
                              : null}
                          </div>
                          <div className="mt-1 shadow-sm w-full">
                            <label class="block text-gray-700 text-base mb-2" for="name">Game Name :</label>
                            <select className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none"
                              id="userId" name="userId" placeholder="name"
                              value={fieldsGame && fieldsGame["userId"] ? fieldsGame["userId"] : ""} type="text" onChange={inputChangeCreate} >
                              <option value="">Select Game Name</option>
                              {gamesList && gamesList.length > 0 ? gamesList.map((element, index) => (
                                <option value={element && element._id ? element._id : ""}>{element && element.name ? element.name : ""}</option>
                              )) : null}

                            </select>
                            {errorsGame && errorsGame["userId"] ?
                              <div className="text-red-600 invalid-feedback">
                                {errorsGame["userId"]}
                              </div>
                              : null}
                          </div>
                          <div className="mt-1 shadow-sm w-full">
                            <label class="block text-gray-700 text-base mb-2" for="name">Game Type :</label>
                            <select className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none"
                              id="userId2" name="userId2" placeholder="name"
                              value={fieldsGame && fieldsGame["userId2"] ? fieldsGame["userId2"] : ""} type="text" onChange={inputChangeCreate} >
                              <option value="">Select Game Type</option>
                              {allMarket && allMarket.length > 0 ? allMarket.map((element, index) => (
                                <option value={element && element._id ? element._id : ""}
                                  data-isopenclose={element && element.isOpenClose
                                    ? element.isOpenClose
                                    : ""}>{element && element.name ? element.name : ""}</option>
                              )) : null}
                            </select>
                            {errorsGame && errorsGame["userId2"] ?
                              <div className="text-red-600 invalid-feedback">
                                {errorsGame["userId2"]}
                              </div>
                              : null}
                          </div>
                          {isOpenClose &&
                            <div className='w-full'>
                              <label className='block text-gray-700 text-base mb-2'>Is OpenClose</label>
                              <select className='w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none' onChange={handleOnChangeOpenClose}>
                                <option value='false'>Select</option>
                                <option value='true'>Open</option>
                                <option value='false'>Close</option>


                              </select>
                            </div>}

                          <div className="w-full flex mt-2 md:mt-0 justify-start items-end">
                            <button className="flex justify-center px-4 py-2 text-base font-semibold text-white capitalize  transition duration-150 ease-in-out bg-[#0F9707] border border-[#0F9707] rounded-xl focus:outline-none" type="button" onClick={createGameSubmit}>Submit</button>
                          </div>
                        </div>
                      </div> */}



                      <div className="px-6 py-4 text-left bg-white space-y-2 rounded-lg">
                        <div className="flex items-center justify-between">
                          <span className="font-semibold">Filter List</span>
                        </div>
                        <div className="flex justify-between md:flex-nowrap gap-1 flex-wrap items-end w-full">
                          <div className="mt-1 shadow-sm w-full">
                            <label className="block text-gray-700 text-base mb-2" htmlFor="gamename">Date :</label>
                            <input
                              className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none"
                              id="date"
                              name="date"
                              max={today}
                              placeholder="Enter date"
                              value={fieldsGame?.["date"] || ""}
                              type="date"
                              onChange={inputChangeCreate}
                            />
                            {errorsGame?.["date"] && (
                              <div className="text-red-600 invalid-feedback">{errorsGame["date"]}</div>
                            )}
                          </div>

                          <div className="mt-1 shadow-sm w-full">
                            <label className="block text-gray-700 text-base mb-2" htmlFor="name">Game Name :</label>
                            <select
                              className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none"
                              id="userId"
                              name="userId"
                              value={fieldsGame?.["userId"] || ""}
                              onChange={inputChangeCreate}
                            >
                              <option value="">Select Game Name</option>
                              {gamesList && gamesList.length > 0 &&
                                gamesList.filter((element) => (!element.brandGame && element.isJackPot)).map((element, index) => (
                                  <option key={index} value={element._id || ""}>
                                    {element.name || ""}
                                  </option>
                                ))}
                            </select>
                            {errorsGame?.["userId"] && (
                              <div className="text-red-600 invalid-feedback">{errorsGame["userId"]}</div>
                            )}
                          </div>

                          <div className="mt-1 shadow-sm w-full">
                            <label className="block text-gray-700 text-base mb-2" htmlFor="name">Game Type :</label>
                            <select
                              className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none"
                              id="userId2"
                              name="userId2"
                              value={fieldsGame?.["userId2"] || ""}
                              onChange={inputChangeCreate}
                            >
                              <option value="">Select Game Type</option>
                              {allMarket && allMarket.length > 0 &&
                                allMarket.filter((element) => (element.isJodi && !element.isStarLine)).map((element, index) => (
                                  <option
                                    key={index}
                                    value={element._id || ""}
                                    data-isopenclose={element.isOpenClose || ""}
                                  >
                                    {element.name || ""}
                                  </option>
                                ))}
                            </select>
                            {errorsGame?.["userId2"] && (
                              <div className="text-red-600 invalid-feedback">{errorsGame["userId2"]}</div>
                            )}
                          </div>

                          {isOpenClose && (
                            <div className="w-full">
                              <label className="block text-gray-700 text-base mb-2">Is OpenClose</label>
                              <select
                                className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none"
                                onChange={handleOnChangeOpenClose}
                              >
                                <option value="false">Select</option>
                                <option value="true">Open</option>
                                <option value="false">Close</option>
                              </select>
                            </div>
                          )}

                          <div className="w-full flex mt-2 md:mt-0 justify-start items-end">
                            <button
                              className="flex justify-center px-4 py-2 text-base font-semibold text-white capitalize transition duration-150 ease-in-out bg-[#0F9707] border border-[#0F9707] rounded-xl focus:outline-none"
                              type="button"
                              onClick={createGameSubmit}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="px-6 py-4 text-left bg-white space-y-2 rounded-lg">
                        <div className='flex justify-between items-center px-3 py-2 border-b'>
                          <span className="p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white ">
                            Bits on Number List
                          </span>
                        </div>

                        {/* <div>
                          <div>Single Digit</div>
                          <div className="relative flex  gap-4 flex-wrap overflow-x-auto p-5">



                            {
                              numberListData && numberListData.length > 0 && numberListData.find(e => e?.marketName == "SINGLEDIGIT")?.numList?.map(e => {
                                let data = numberListData?.find(exem => exem?.marketName == "SINGLEDIGIT")?.bets
                                console.log("asjdvhsajkdv", data)
                                let amount = data.length > 0 && data.find(elem => elem.k == e.toString())
                                console.log("amountamount", amount)

                                return (

                                  <>

                                    <div className=' w-14  flex justify-center flex-col items-center  h-14 bg-gray-300'>
                                      <div className=' border-2 w-full text-center'>{e}</div>
                                      {amount?.v > 0 ? <div className=' border-2 w-full text-center'><div className=' bg-green-500 text-white  rounded-full text-sm  text-center font-bold'>{amount?.v || 0}</div></div> :
                                        <div className=' border-2 w-full text-center'><div className=' bg-red-500 text-white  rounded-full text-sm  text-center font-bold'>{amount?.v || 0}</div></div>}


                                    </div>
                                  </>

                                )
                              }
                              )

                            }
                          </div>

                        </div> */}

                        <div>
                          <div>Jodi</div>
                          <div className="relative flex  gap-4 flex-wrap overflow-x-auto p-5">



                            {
                              numberListData && numberListData.length > 0 && numberListData.find(e => e?.marketName == "JODI")?.numList?.flat(Infinity)?.map(e => {
                                let data = numberListData?.find(exem => exem?.marketName == "JODI")?.bets
                                //  console.log("asjdvhsajkdv",data)
                                let amount = data.length > 0 && data.find(elem => elem.k == e.toString())
                                //  console.log("amountamount",amount)

                                return (

                                  <>

                                    <div className=' w-14  flex justify-center flex-col items-center  h-14 bg-gray-300'>
                                      <div className=' border-2 w-full text-center'>{e}</div>
                                      {amount?.v > 0 ? <div className=' border-2 w-full text-center'><div className=' bg-green-500 text-white  rounded-full text-sm  text-center font-bold'>{amount?.v || 0}</div></div> :
                                        <div className=' border-2 w-full text-center'><div className=' bg-red-500 text-white  rounded-full text-sm  text-center font-bold'>{amount?.v || 0}</div></div>}


                                    </div>
                                  </>

                                )
                              }
                              )

                            }
                          </div>

                        </div>


                        {/* <div>
                          <div>Single Pana</div>
                          <div className="relative flex  gap-4 flex-wrap overflow-x-auto p-5">



                            {
                              numberListData && numberListData.length > 0 && numberListData.find(e => e?.marketName == "SINGLEPANA")?.numList?.flat(Infinity)?.map(e => {
                                let data = numberListData?.find(exem => exem?.marketName == "SINGLEPANA")?.bets
                                //  console.log("asjdvhsajkdv",data)
                                let amount = data.length > 0 && data.find(elem => elem.k == e.toString())
                                //  console.log("amountamount",amount)

                                return (

                                  <>

                                    <div className=' w-14  flex justify-center flex-col items-center  h-14 bg-gray-300'>
                                      <div className=' border-2 w-full text-center'>{e}</div>
                                      {amount?.v > 0 ? <div className=' border-2 w-full text-center'><div className=' bg-green-500 text-white  rounded-full text-sm  text-center font-bold'>{amount?.v || 0}</div></div> :
                                        <div className=' border-2 w-full text-center'><div className=' bg-red-500 text-white  rounded-full text-sm  text-center font-bold'>{amount?.v || 0}</div></div>}


                                    </div>
                                  </>

                                )
                              }
                              )

                            }
                          </div>

                        </div> */}




                        {/* <div>
                          <div>Double Pana</div>
                          <div className="relative flex  gap-4 flex-wrap overflow-x-auto p-5">



                            {
                              numberListData && numberListData.length > 0 && numberListData.find(e => e?.marketName == "DOUBLEPANA")?.numList?.flat(Infinity)?.map(e => {
                                let data = numberListData?.find(exem => exem?.marketName == "DOUBLEPANA")?.bets
                                //  console.log("asjdvhsajkdv",data)
                                let amount = data.length > 0 && data.find(elem => elem.k == e.toString())
                                //  console.log("amountamount",amount)

                                return (

                                  <>

                                    <div className=' w-14  flex justify-center flex-col items-center  h-14 bg-gray-300'>
                                      <div className=' border-2 w-full text-center'>{e}</div>
                                      {amount?.v > 0 ? <div className=' border-2 w-full text-center'><div className=' bg-green-500 text-white  rounded-full text-sm  text-center font-bold'>{amount?.v || 0}</div></div> :
                                        <div className=' border-2 w-full text-center'><div className=' bg-red-500 text-white  rounded-full text-sm  text-center font-bold'>{amount?.v || 0}</div></div>}


                                    </div>
                                  </>

                                )
                              }
                              )

                            }
                          </div>

                        </div> */}




                        {/* <div>
                          <div>Triple Pana</div>
                          <div className="relative flex  gap-4 flex-wrap overflow-x-auto p-5">



                            {
                              numberListData && numberListData.length > 0 && numberListData.find(e => e?.marketName == "TRIPLEPANA")?.numList?.flat(Infinity)?.map(e => {
                                let data = numberListData?.find(exem => exem?.marketName == "TRIPLEPANA")?.bets
                                //  console.log("asjdvhsajkdv",data)
                                let amount = data.length > 0 && data.find(elem => elem.k == e.toString())
                                //  console.log("amountamount",amount)

                                return (

                                  <>

                                    <div className=' w-14  flex justify-center flex-col items-center  h-14 bg-gray-300'>
                                      <div className=' border-2 w-full text-center'>{e}</div>
                                      {amount?.v > 0 ? <div className=' border-2 w-full text-center'><div className=' bg-green-500 text-white  rounded-full text-sm  text-center font-bold'>{amount?.v || 0}</div></div> :
                                        <div className=' border-2 w-full text-center'><div className=' bg-red-500 text-white  rounded-full text-sm  text-center font-bold'>{amount?.v || 0}</div></div>}


                                    </div>
                                  </>

                                )
                              }
                              )

                            }
                          </div>

                        </div> */}


                        {/* Sp Motor */}

                        {/* <div>
<div>SP Motor</div>
<div className="relative flex  gap-4 flex-wrap overflow-x-auto p-5">
{
  numberListData &&
  numberListData.length > 0 &&
  numberListData?.find(e => e?.marketName === "SP_MOTOR")?.numList &&
  Object.keys(numberListData.find(e => e?.marketName === "SP_MOTOR")?.numList[0])?.map(key => {

    
return(
  <div className=' flex '>
  <div>{key}</div>
  <div className=' relative flex  gap-4 flex-wrap overflow-x-auto p-5 '>

  
 { numberListData?.find(e => e?.marketName === "SP_MOTOR")?.numList[0]?.[key]?.map(e => {
    let data = numberListData.find(exem => exem?.marketName === "SP_MOTOR")?.bets;
    let amount = data.length > 0 && data?.find(elem => elem.k === e.toString());

    return (<div>
    
      <div key={e} className='w-14 flex justify-center flex-col items-center h-14 bg-gray-300'>
        <div className='border-2 w-full text-center'>{e}</div>
        {amount?.v > 0 ? (
          <div className='border-2 w-full text-center'>
            <div className='bg-green-500 text-white rounded-full text-sm text-center font-bold'>{amount?.v || 0}</div>
          </div>
        ) : (
          <div className='border-2 w-full text-center'>
            <div className='bg-red-500 text-white rounded-full text-sm text-center font-bold'>{amount?.v || 0}</div>
          </div>
        )}
      </div>
        </div>
    );
  })}
</div>
</div>
)

  }
  )
}
</div>
</div> */}




                        {/* DP Motor */}

                        {/* <div>
<div>DP Motor</div>
<div className="relative flex  gap-4 flex-wrap overflow-x-auto p-5">
{
  numberListData &&
  numberListData.length > 0 &&
  numberListData?.find(e => e?.marketName === "DP_MOTOR")?.numList &&
  Object.keys(numberListData.find(e => e?.marketName === "DP_MOTOR")?.numList[0])?.map(key => {

    
return(
  <div className=' flex '>
  <div>{key}</div>
  <div className=' relative flex  gap-4 flex-wrap overflow-x-auto p-5 '>

  
 { numberListData?.find(e => e?.marketName === "DP_MOTOR")?.numList[0]?.[key]?.map(e => {
    let data = numberListData.find(exem => exem?.marketName === "DP_MOTOR")?.bets;
    let amount = data.length > 0 && data?.find(elem => elem.k === e.toString());

    return (<div>
    
      <div key={e} className='w-14 flex justify-center flex-col items-center h-14 bg-gray-300'>
        <div className='border-2 w-full text-center'>{e}</div>
        {amount?.v > 0 ? (
          <div className='border-2 w-full text-center'>
            <div className='bg-green-500 text-white rounded-full text-sm text-center font-bold'>{amount?.v || 0}</div>
          </div>
        ) : (
          <div className='border-2 w-full text-center'>
            <div className='bg-red-500 text-white rounded-full text-sm text-center font-bold'>{amount?.v || 0}</div>
          </div>
        )}
      </div>
        </div>
    );
  })}
</div>
</div>
)

  }
  )
}
</div>
</div> */}



                      </div>

                    </div>

                  </div>

                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BetHistoryJackPot;