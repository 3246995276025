import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardJSON from "./AppSettingManagement.json";
import { appSettingActions, userActions } from "../../_actions";
import CreateAppSettingModal from "./components/CreateAppSettingModal/CreateAppSettingModal";
import UpdateAppSettingModal from "./components/UpdateAppSettingModal/UpdateAppSettingModal";
import ViewAppSettingModal from "./components/ViewAppSettingModal/ViewAppSettingModal";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ReactPaginate from "react-paginate";
import { isMobile } from "react-device-detect";
import Loader from "../../components/Loader/Loader";
import { FaRegSave } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// import Table from '../../components/Atoms/Table/Table';

const AppSettingManagement = () => {
  const dispatch = useDispatch();
  let [selectedFile, setSelectedFile] = useState(null);
  let [rowData, setRowData] = useState(null);
  let [updateModal, setUpdateModal] = useState(false);
  let [createModal, setCreateModal] = useState(false);
  let [viewModal, setViewModal] = useState(false);
  const [fieldsUpdate, setFieldsUpdate] = useState({});
  const [errorsUpdate, setErrorsUpdate] = useState({});
  const [fieldsAppSetting, setFieldsAppSetting] = useState({});
  const [errorsAppSetting, setErrorsAppSetting] = useState({});
  const [size, setSize] = useState(50);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [value, setValue] = useState("");
  const selector = useSelector(state => state);
  const { appSettingList, total, loading } = useSelector(
    (state) => state.appSettings
  );

  const [initialState, setInitialState] = useState({
    _id: "",
    name: "",
    key: "",
    value: "",
  });

  const handleChange2 = (e, element) => {
    console.log(element);
    if (appSettingList && appSettingList.length > 0) {
      appSettingList.forEach((element) => {
        if (element.key === "YoutubeLink" && e.key === "Youtube") {
          setstate1(e.target.value);
        }
        if (element.key === "Email") {
          setstate2(e.target.value);
        }
        if (element.key === "WhatsApp") {
          setstate3(e.target.value);
        }
        if (element.key === "MobileNumber") {
          setstate4(e.target.value);
        }
        if (element.key === "TelegramLink") {
          setstate5(e.target.value);
        }
      });
    }
  };

  // console.log(initialState)

  useEffect(() => {
    let appsettingReq = {
      keyWord: "",
      pageNo: pageNo,
      size: size,
    };
    dispatch(appSettingActions.getAppSettingList(appsettingReq));
  }, []);

  const handleCreateOpenModal = () => {
    setCreateModal(true);
  };
  const savedatadata = (e) => {
    console.log(e);
  };

  const handleCreateHideModal = (data) => {
    setCreateModal(false);
    setFieldsAppSetting({});
    setErrorsAppSetting({});
  };

  // const handleUpdateOpenModal = (data) => {
  //   setFieldsUpdate(data);
  //   setUpdateModal(true);
  // }

  const handleUpdateHideModal = (data) => {
    setUpdateModal(false);
    setErrorsUpdate({});
  };

  const handleViewOpenModal = (data) => {
    setRowData(data);
    setViewModal(true);
  };

  const handleViewHideModal = (data) => {
    setViewModal(false);
  };

  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsUpdate((prevState) => ({ ...prevState, [name]: value }));
    setErrorsUpdate((prevState) => ({ ...prevState, [name]: "" }));
  };

  const inputChangeCreate = (e) => {
    // e.preventDefault();
    // console.log(e)
    // const { name, value } = e.target;
    // setFieldsAppSetting(prevState => ({ ...prevState, [name]: value }));
    // setErrorsAppSetting(prevState => ({ ...prevState, [name]: "" }));
  };

  const updateAppSettingSubmit = (e) => {
    e.preventDefault();
    if (handleValidationUpdateAppSetting()) {
      let appsettingPageRefresh = {
        keyWord: "",
        pageNo: pageNo,
        size: size,
      };

      const { name, key, value, _id } = fieldsUpdate;
      dispatch(
        appSettingActions.updateAppSetting(
          {
            name: name,
            key: key,
            value: value,
            id: _id,
          },
          setUpdateModal,
          appsettingPageRefresh
        )
      );
    }
  };

  const handleValidationUpdateAppSetting = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsUpdate.name || fieldsUpdate.name === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (!fieldsUpdate.key || fieldsUpdate.key === "") {
      formIsValid = false;
      errors.key = DashboardJSON.ErrorMsg.key;
    }

    if (!fieldsUpdate.value || fieldsUpdate.value === "") {
      formIsValid = false;
      errors.value = DashboardJSON.ErrorMsg.value;
    }

    setErrorsUpdate(errors);
    return formIsValid;
  };

  const createAppSettingSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreateAppSetting()) {
      let appsettingPageRefresh = {
        keyWord: "",
        pageNo: pageNo,
        size: size,
      };

      const { name, key, value, _id } = fieldsAppSetting;
      dispatch(
        appSettingActions.createAppSetting(
          {
            name: name,
            key: key,
            value: value,
            id: _id,
          },
          setCreateModal,
          setFieldsAppSetting,
          appsettingPageRefresh
        )
      );
    }
  };

  const handleValidationCreateAppSetting = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsAppSetting.name || fieldsAppSetting.name === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (!fieldsAppSetting.key || fieldsAppSetting.key === "") {
      formIsValid = false;
      errors.key = DashboardJSON.ErrorMsg.key;
    }

    if (!fieldsAppSetting.value || fieldsAppSetting.value === "") {
      formIsValid = false;
      errors.value = DashboardJSON.ErrorMsg.value;
    }

    setErrorsAppSetting(errors);
    return formIsValid;
  };

  const disableAppSetting = (data) => {
    let appsettingIdReq = {
      id: data._id,
    };
    let appsettingPageRefresh = {
      keyWord: "",
      pageNo: pageNo,
      size: size,
    };
    confirmAlert({
      title: "Confirm to disable AppSetting?",
      message: `Are you sure to disable ${data.name}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(
              appSettingActions.updateAppSettingStatus(
                appsettingIdReq,
                appsettingPageRefresh
              )
            ),
        },
        {
          label: "No",
        },
      ],
    });
  };

  // const deleteAppSetting = (data) => {
  //   let appsettingIdReq = {
  //     "id": data._id,
  //   }

  //   let appsettingPageRefresh = {
  //     "keyWord": "",
  //     "pageNo": pageNo,
  //     "size": size
  //   }

  //   confirmAlert({

  //     title: 'Confirm to Delete?',
  //     message: `Are you sure you want  to delete ${data.name}?`,
  //     buttons: [
  //       {
  //         label: 'Yes',
  //         onClick: () => dispatch(appSettingActions.deleteAppSetting(appsettingIdReq, appsettingPageRefresh))
  //       },
  //       {
  //         label: 'No'
  //       }
  //     ]
  //   });
  // }

  const handleFile = (event) => {
    if (event.target.files[0]) {
      dispatch(userActions.uploadImage(event.target.files[0]));
    } else {
      console.log("No File To Upload!");
    }
  };
  const uploadforApk = (event) => {
    if (event.target.files[0]) {
      dispatch(userActions.uploadforApk(event.target.files[0]));
    } else {
      console.log("No File To Upload!");
    }
  };

  const [state1, setstate1] = useState("");
  const [state2, setstate2] = useState("");
  const [state3, setstate3] = useState("");
  const [state4, setstate4] = useState("");
  const [state5, setstate5] = useState("");
  const [state9, setstate9] = useState("");
  const [state10, setstate10] = useState("");
  const [state11, setstate11] = useState("");
  const [state12, setstate12] = useState("");
  const [state13, setstate13] = useState("");
  const [state14, setstate14] = useState("");
  const [state16, setstate16] = useState("");
  const [state19, setstate19] = useState("");
  const [state20, setstate20] = useState("");
  const [state100, setstate100] = useState("");

  useEffect(() => {
    if (appSettingList && appSettingList.length > 0) {
      appSettingList.forEach((element) => {
        switch (element.key) {
          case "UPI_IMAGE":
            setstate100(element.value);
            break;
          case "withDraw_close_Time":
            setstate14(element.value);
            break;
          case "Update_Link":
            setstate12(element.value);
            break;
          case "withDraw_open_Time":
            setstate13(element.value);
            break;
          case "YoutubeLink":
            setstate1(element.value);
            break;
          case "Email":
            setstate4(element.value);
            break;
          case "WhatsApp":
            setstate3(element.value);
            break;
          case "MobileNumber":
            setstate2(element.value);
            break;
          case "TelegramLink":
            setstate5(element.value);
            break;
          case "HowToPlay":
            setValue(element.value);
            break;
          case "MIN_WITHDRAWL_LIMIT":
            setstate9(element.value);
            break;
          case "MAX_WITHDRAWL_LIMIT":
            setstate10(element.value);
            break;
          case "MINIMUM_DEPOSIT":
            setstate11(element.value);
            break;
          case "Update_Link":
            setstate12(element.value);
            break;
          case "Version":
            setstate16(element.value);
            break;
          case "OnlinePayment":
            setstate19(element.value);
            break;
          case "Maximum_Deposit":
            setstate20(element.value);
            break;
          default:
            break;
        }
      });
    }
  }, [appSettingList]);

  const saveInfo = async (e) => {
    let dataofvalue;

    console.log(e.key);
    switch (e.key) {
      case "UPI_IMAGE":
        let { users } = selector ? selector : {}
        let { fileData } = users ? users : {}
        dataofvalue = fileData ? fileData : state100;
        break;
      case "Maximum_Deposit":
        dataofvalue = state20;
        break;
      case "OnlinePayment":
        dataofvalue = state19;
        break;
      case "Version":
        dataofvalue = state16;
        break;
      case "withDraw_close_Time":
        dataofvalue = state14;
        break;
      case "withDraw_open_Time":
        dataofvalue = state13;
        break;

      case "Update_Link":
        dataofvalue = state12;
        break;
      case "MINIMUM_DEPOSIT":
        dataofvalue = state11;
        break;
      case "MAX_WITHDRAWL_LIMIT":
        dataofvalue = state10;
        break;
      case "MIN_WITHDRAWL_LIMIT":
        dataofvalue = state9;
        break;
      case "TelegramLink":
        dataofvalue = state5;
        break;
      case "YoutubeLink":
        dataofvalue = state1;
        break;
      case "Email":
        dataofvalue = state4;
        break;
      case "WhatsApp":
        dataofvalue = state3;
        break;
      case "MobileNumber":
        dataofvalue = state2;
        break;
      case "HowToPlay":
        dataofvalue = value;
        break;
      default:
        dataofvalue = null; // or handle default case if needed
        break;
    }

    let appsettingPageRefresh = {
      keyWord: "",
      pageNo: pageNo,
      size: size,
    };

    let Obj = {
      name: e?.name,
      key: e?.key,
      value: dataofvalue,
      id: e?._id,
    };

    dispatch(appSettingActions.updateAppSetting(Obj, appsettingPageRefresh));
  };


  let { users } = selector ? selector : {}
  let { fileData } = users ? users : {}
  console.log("appSettingList", appSettingList)

  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-scroll focus:outline-none  ">
        <div className="relative h-100vh ">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl">
                      <sectiion className="flex justify-between my-4 ">
                        <div>
                          <p className="text-2xl font-bold">
                            App Setting Management
                          </p>
                        </div>
                        <div>
                          <span className="relative "></span> &nbsp;
                        </div>
                      </sectiion>

                      <div>
                        <div className="relative   gap-5 ">
                          <div className=" grid grid-cols-1 lg:grid-cols-2 items-center gap-5  ">
                            {appSettingList && appSettingList.length > 0
                              ? appSettingList.map((element, index) => {
                                if (element.key === "TelegramLink") {
                                  return (
                                    <form
                                      class="flex  w-full justify-start items-center  "
                                      key={index}
                                    >
                                      <div className=" relative w-full">
                                        <label
                                          for="text"
                                          class="block mb-1 text-sm font-medium text-gray-900 "
                                        >
                                          {element && element.name}{" "}
                                        </label>
                                        <input
                                          value={state5}
                                          onChange={(e) =>
                                            setstate5(e.target.value)
                                          }
                                          type="text"
                                          id="text"
                                          class="capitalize bg-white  w-full  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                          required
                                        />
                                        <FaRegSave
                                          title="Save"
                                          onClick={(e) =>
                                            saveInfo(element, e)
                                          }
                                          className=" text-green-600 absolute  top-1/2 right-2 text-xl cursor-pointer"
                                        />
                                      </div>
                                      <div></div>
                                    </form>
                                  );
                                }

                                if (element.key === "MobileNumber") {
                                  return (
                                    <form class=" w-full  ">
                                      <div className=" w-full relative">
                                        <div className="text-xl">
                                          <label
                                            for="text"
                                            class="block mb-1 text-sm font-medium text-gray-900 "
                                          >
                                            MobileNumber
                                          </label>
                                          <input
                                            rows={3}
                                            value={state2}
                                            onChange={(e) =>
                                              setstate2(e.target.value)
                                            }
                                            type="text"
                                            id="text"
                                            class="bg-white  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-6 py-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            required
                                          />
                                          {/* <div title='Save' onClick={(e) => saveInfo(element, e)} className=' text-white bg-green-600 px-2 py-1 rounded-lg cursor-pointer'>Save</div> */}
                                        </div>

                                        {/* <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-green-600 absolute  top-1/2 right-2 text-xl cursor-pointer' /> */}
                                        <FaRegSave
                                          className="text-green-600 absolute  top-1/2 right-2 text-xl cursor-pointer"
                                          title="Save"
                                          onClick={(e) =>
                                            saveInfo(element, e)
                                          }
                                        />
                                      </div>
                                      <div></div>
                                    </form>
                                  );
                                }
                                if (element.key === "WhatsApp") {
                                  return (
                                    <form class="">
                                      <div className="w-full  relative">
                                        <div className=" w-full justify-between items-center text-xl ">
                                          <label
                                            for="text"
                                            class="block mb-1 text-sm font-medium text-gray-900 "
                                          >
                                            WhatsApp{" "}
                                          </label>
                                          <input
                                            rows={3}
                                            value={state3}
                                            onChange={(e) =>
                                              setstate3(e.target.value)
                                            }
                                            type="text"
                                            id="text"
                                            class="bg-gray-50 w-full border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  px-6 py-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            required
                                          />
                                        </div>
                                        <FaRegSave
                                          className="text-green-600 absolute  top-1/2 right-2 text-xl cursor-pointer"
                                          onClick={(e) =>
                                            saveInfo(element, e)
                                          }
                                        />
                                        {/* <button title='Save' 
                                            className='absolute text-white bg-green-600 px-2  rounded-lg cursor-pointer'>Save</button> */}
                                      </div>
                                      <div></div>
                                    </form>
                                  );
                                }
                                if (element.key === "Email") {
                                  return (
                                    <form class="flex w-full  justify-start items-center mt-2 ">
                                      <div className="w-full relative">
                                        <label
                                          for="text"
                                          class="block mb-1 text-sm font-medium text-gray-900 "
                                        >
                                          {element && element.name}{" "}
                                        </label>
                                        <input
                                          value={state4}
                                          onChange={(e) =>
                                            setstate4(e.target.value)
                                          }
                                          type="text"
                                          id="text"
                                          class="bg-gray-50 capitalize border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                          required
                                        />
                                        <FaRegSave
                                          title="Save"
                                          onClick={(e) =>
                                            saveInfo(element, e)
                                          }
                                          className=" text-green-600 absolute  top-1/2 right-2 text-xl cursor-pointer"
                                        />
                                      </div>
                                      <div></div>
                                    </form>
                                  );
                                }
                                if (element.key === "YoutubeLink") {
                                  return (
                                    <form class="flex  justify-start w-full items-center mt-2 ">
                                      <div className="w-full relative">
                                        <label
                                          for="text"
                                          class="block mb-1 text-sm font-medium text-gray-900 "
                                        >
                                          {element && element.name}{" "}
                                        </label>
                                        <input
                                          value={state1}
                                          onChange={(e) =>
                                            setstate1(e.target.value)
                                          }
                                          type="text"
                                          id="text"
                                          class="pe-20 bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                          required
                                        />
                                        <FaRegSave
                                          title="Save"
                                          onClick={(e) =>
                                            saveInfo(element, e)
                                          }
                                          className=" text-green-600 absolute  top-1/2 right-2 text-xl cursor-pointer"
                                        />
                                      </div>
                                      <div></div>
                                    </form>
                                  );
                                }
















                              })
                              : null}
                          </div>

                          <div className=" flex-1 h-full my-6">
                            {appSettingList && appSettingList.length > 0
                              ? appSettingList.map((element, index) => {
                                if (
                                  element &&
                                  element.key &&
                                  element.key === "HowToPlay"
                                ) {
                                  return (
                                    <div className="">
                                      <div className=" mb-1">
                                        {" "}
                                        How To Play
                                      </div>
                                      <ReactQuill
                                        className="h-96  w-full   pb-14"
                                        theme="snow"
                                        value={value}
                                        onChange={setValue}
                                      />
                                      <div className="flex justify-end">
                                        <button
                                          className="bg-green-600 px-4 font-semibold py-1  text-white rounded-md"
                                          onClick={(e) =>
                                            saveInfo(element, e)
                                          }
                                        >
                                          {" "}
                                          Save
                                        </button>
                                      </div>
                                    </div>
                                  );
                                }
                              })
                              : null}

                            <div className=" grid grid-cols-1 lg:grid-cols-2 items-center gap-5  ">
                              {appSettingList && appSettingList.length > 0
                                ? appSettingList.map((element, index) => {
                                  if (element.key === "MIN_WITHDRAWL_LIMIT") {
                                    return (
                                      <form class="flex  justify-start w-full items-center mt-2 ">
                                        <div className="w-full relative">
                                          <label
                                            for="text"
                                            class="block mb-1 text-sm font-medium text-gray-900 "
                                          >
                                            {element && element.name}{" "}
                                          </label>
                                          <input
                                            type="number"
                                            value={state9}
                                            onChange={(e) =>
                                              setstate9(e.target.value)
                                            }
                                            id="text"
                                            class="pe-20 bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            required
                                          />
                                          <FaRegSave
                                            title="Save"
                                            onClick={(e) =>
                                              saveInfo(element, e)
                                            }
                                            className=" text-green-600 absolute  top-1/2 right-2 text-xl cursor-pointer"
                                          />
                                        </div>
                                        <div></div>
                                      </form>
                                    );
                                  }
                                  if (element.key === "MAX_WITHDRAWL_LIMIT") {
                                    return (
                                      <form class="flex  justify-start w-full items-center mt-2 ">
                                        <div className="w-full relative">
                                          <label
                                            for="text"
                                            class="block mb-1 text-sm font-medium text-gray-900 "
                                          >
                                            {element && element.name}{" "}
                                          </label>
                                          <input
                                            value={state10}
                                            onChange={(e) =>
                                              setstate10(e.target.value)
                                            }
                                            type="number"
                                            id="text"
                                            class="pe-20 bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            required
                                          />
                                          <FaRegSave
                                            title="Save"
                                            onClick={(e) =>
                                              saveInfo(element, e)
                                            }
                                            className=" text-green-600 absolute  top-1/2 right-2 text-xl cursor-pointer"
                                          />
                                        </div>
                                        <div></div>
                                      </form>
                                    );
                                  }
                                  if (element.key === "MINIMUM_DEPOSIT") {
                                    return (
                                      <form class="flex  justify-start w-full items-center mt-2 ">
                                        <div className="w-full relative">
                                          <label
                                            for="text"
                                            class="block mb-1 text-sm font-medium text-gray-900 "
                                          >
                                            {element && element.name}{" "}
                                          </label>
                                          <input
                                            value={state11}
                                            onChange={(e) =>
                                              setstate11(e.target.value)
                                            }
                                            type="number"
                                            id="text"
                                            class="pe-20 bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            required
                                          />
                                          <FaRegSave
                                            title="Save"
                                            onClick={(e) =>
                                              saveInfo(element, e)
                                            }
                                            className=" text-green-600 absolute  top-1/2 right-2 text-xl cursor-pointer"
                                          />
                                        </div>
                                        <div></div>
                                      </form>
                                    );
                                  }
                                  if (element.key === "Maximum_Deposit") {
                                    return (
                                      <form class="flex  justify-start w-full items-center mt-2 ">
                                        <div className="w-full relative">
                                          <label
                                            for="text"
                                            class="block mb-1 text-sm font-medium text-gray-900 "
                                          >
                                            {element && element.name}{" "}
                                          </label>
                                          <input
                                            value={state20}
                                            onChange={(e) =>
                                              setstate20(e.target.value)
                                            }
                                            type="number"
                                            id="text"
                                            class="pe-20 bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            required
                                          />
                                          <FaRegSave
                                            title="Save"
                                            onClick={(e) =>
                                              saveInfo(element, e)
                                            }
                                            className=" text-green-600 absolute  top-1/2 right-2 text-xl cursor-pointer"
                                          />
                                        </div>
                                        <div></div>
                                      </form>
                                    );
                                  }
                                })
                                : null}
                            </div>

                            <div className=" grid grid-cols-1 lg:grid-cols-2 items-center gap-5  ">
                              {appSettingList && appSettingList.length > 0
                                ? appSettingList.map((element, index) => {
                                  if (element.key === "withDraw_open_Time") {
                                    return (
                                      <form class="flex  justify-start w-full items-center mt-2 ">
                                        <div className="w-full relative">
                                          <label
                                            for="text"
                                            class="block mb-1 text-sm font-medium text-gray-900 "
                                          >
                                            {element && element.name}{" "}
                                          </label>
                                          <input
                                            value={state13}
                                            onChange={(e) =>
                                              setstate13(e.target.value)
                                            }
                                            type="time"
                                            id="text"
                                            class="pe-20 bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            required
                                          />
                                          <FaRegSave
                                            title="Save"
                                            onClick={(e) =>
                                              saveInfo(element, e)
                                            }
                                            className=" text-green-600 absolute  top-1/2 right-2 text-xl cursor-pointer"
                                          />
                                        </div>
                                        <div></div>
                                      </form>
                                    );
                                  }
                                  if (element.key === "withDraw_close_Time") {
                                    return (
                                      <form class="flex  justify-start w-full items-center mt-2 ">
                                        <div className="w-full relative">
                                          <label
                                            for="text"
                                            class="block mb-1 text-sm font-medium text-gray-900 "
                                          >
                                            {element && element.name}{" "}
                                          </label>
                                          <input
                                            value={state14}
                                            onChange={(e) =>
                                              setstate14(e.target.value)
                                            }
                                            type="time"
                                            id="text"
                                            class="pe-20 bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            required
                                          />
                                          <FaRegSave
                                            title="Save"
                                            onClick={(e) =>
                                              saveInfo(element, e)
                                            }
                                            className=" text-green-600 absolute  top-1/2 right-2 text-xl cursor-pointer"
                                          />
                                        </div>
                                        <div></div>
                                      </form>
                                    );
                                  }
                                })
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div>App Update Management</div>
                        <div className=" grid grid-cols-1 lg:grid-cols-2 items-center gap-5  ">
                          {appSettingList && appSettingList.length > 0
                            ? appSettingList.map((element, index) => {
                              if (element.key === "Version") {
                                return (
                                  <form class="flex  justify-start w-full items-center mt-2 ">
                                    <div className="w-full relative">
                                      <label
                                        for="text"
                                        class="block mb-1 text-sm font-medium text-gray-900 "
                                      >
                                        {element && element.name}{" "}
                                      </label>
                                      <input
                                        value={state16}
                                        onChange={(e) =>
                                          setstate16(e.target.value)
                                        }
                                        type="number"
                                        id="text"
                                        class="pe-20 bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        required
                                      />
                                      <FaRegSave
                                        title="Save"
                                        onClick={(e) => saveInfo(element, e)}
                                        className=" text-green-600 absolute  top-1/2 right-2 text-xl cursor-pointer"
                                      />
                                    </div>
                                    <div></div>
                                  </form>
                                );
                              }

                              if (element.key === "Update_Link") {
                                return (
                                  <form class="flex  justify-start w-full items-center mt-2 ">
                                    <div className="w-full relative">
                                      <label
                                        for="text"
                                        class="block mb-1 text-sm font-medium text-gray-900 "
                                      >
                                        {element && element.name}{" "}
                                      </label>
                                      {/* <input
                                          value={state12}
                                          onChange={(e) =>
                                            setstate12(e.target.value)
                                          }
                                          type="text"
                                          id="text"
                                          class="pe-20 bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                          required
                                        /> */}





                                      <input
                                        type="file"
                                        id="image"
                                        // accept="image/*" 
                                        onChange={(e) => uploadforApk(e)}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        required
                                      />


                                      <FaRegSave
                                        title="Save"
                                        onClick={(e) => saveInfo(element, e)}
                                        className=" text-green-600 absolute  top-1/2 right-2 text-xl cursor-pointer"
                                      />
                                    </div>
                                    <div></div>
                                  </form>
                                );
                              }
                            })
                            : null}
                        </div>
                      </div>

























                      <div>
                        <div>Payment Management</div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 items-center gap-5">
                          {appSettingList && appSettingList.length > 0
                            ? appSettingList.map((element, index) => {
                              if (element.key === "OnlinePayment") {
                                return (
                                  <form className="flex justify-start w-full items-center mt-2" key={index}>
                                    {/* Toggle Switch */}
                                    <div className="ml-4">
                                      <label className="inline-flex items-center cursor-pointer">
                                        <span className="mr-2 text-sm text-gray-900">{element.name}</span>
                                        <input
                                          type="checkbox"
                                          checked={state19 === '1'} // Toggle based on state19
                                          onChange={(e) => setstate19(e.target.checked ? '1' : '0')}
                                          className="hidden"
                                        />
                                        <div className={`w-12 h-6 rounded-full ${state19 === '1' ? 'bg-green-500' : 'bg-gray-400'} relative transition-colors duration-300`}>
                                          <div
                                            className={`dot absolute left-1 top-1 w-4 h-4 rounded-full bg-white transition-transform duration-300 ${state19 === '1' ? 'transform translate-x-6' : ''
                                              }`}
                                          ></div>
                                        </div>
                                      </label>
                                    </div>

                                    {/* Save Icon */}
                                    <div className="ml-4">
                                      <FaRegSave
                                        title="Save"
                                        onClick={(e) => saveInfo(element, e)}
                                        className="text-green-600 text-xl cursor-pointer"
                                      />
                                    </div>
                                  </form>
                                );
                              }
                            })
                            : null}
                        </div>
                      </div>













                      <div>
                        <div>QR Management </div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 items-center gap-5">
                          {appSettingList && appSettingList.length > 0
                            ? appSettingList.map((element, index) => {
                              if (element.key === "UPI_IMAGE") {
                                return (
                                  <form className="flex justify-start w-full items-center mt-2">
                                    <div className='w-full relative'>

                                      <img src={fileData ? fileData : state100} className=' w-28 h-28' />
                                      <label htmlFor="image" className="block mb-1 text-sm font-medium text-gray-900">
                                        {element && element.name}
                                      </label>

                                      {/* Image Input */}
                                      <input
                                        type="file"
                                        id="image"
                                        accept="image/*"
                                        onChange={(e) => handleFile(e)}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        required
                                      />

                                      {/* Save Button */}
                                      <FaRegSave
                                        title='Save'
                                        onClick={(e) => saveInfo(element, e)}
                                        className='text-[#F64401] absolute top-1/2 right-2 text-xl cursor-pointer'
                                      />
                                    </div>
                                  </form>
                                )
                              }
                            })
                            : null}
                        </div>
                      </div>







                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateAppSettingModal
        createModal={createModal}
        handleCreateHideModal={handleCreateHideModal}
        handleFile={handleFile}
        inputChangeCreate={inputChangeCreate}
        fieldsAppSetting={fieldsAppSetting}
        errorsAppSetting={errorsAppSetting}
        createAppSettingSubmit={createAppSettingSubmit}
      />

      <UpdateAppSettingModal
        updateModal={updateModal}
        handleUpdateHideModal={handleUpdateHideModal}
        handleFile={handleFile}
        inputChange={inputChange}
        fieldsUpdate={fieldsUpdate}
        errorsUpdate={errorsUpdate}
        updateAppSettingSubmit={updateAppSettingSubmit}
      />

      <ViewAppSettingModal
        viewModal={viewModal}
        handleViewHideModal={handleViewHideModal}
        rowData={rowData}
      />
    </>
  );
};

export default AppSettingManagement;
