import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardJSON from "./MarketManagement.json";
import { marketActions, userActions } from "../../_actions";
import CreateMarketModal from "./components/CreateMarketModal/CreateMarketModal";
import UpdateMarketModal from "./components/UpdateMarketModal/UpdateMarketModal";
import ViewMarketModal from "./components/ViewMarketModal/ViewMarketModal";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ReactPaginate from "react-paginate";
import { isMobile } from "react-device-detect";
import { FaEdit, FaEye } from "react-icons/fa";
import { MdOutlineDelete } from "react-icons/md";
import { FiEdit } from "react-icons/fi";

const JackpotMarketMangment = () => {
  let addref = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [selectedFile, setSelectedFile] = useState(null);
  let [rowData, setRowData] = useState(null);
  let [updateModal, setUpdateModal] = useState(false);
  let [createModal, setCreateModal] = useState(false);
  let [viewModal, setViewModal] = useState(false);
  const [fieldsUpdate, setFieldsUpdate] = useState({});
  const [errorsUpdate, setErrorsUpdate] = useState({});
  const [fieldsMarket, setFieldsMarket] = useState({});
  const [errorsMarket, setErrorsMarket] = useState({});
  const [numListUpdate, setNumListUpdate] = useState([]);
  const [size, setSize] = useState(100);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);

  const { marketList, marketTotal } = useSelector((state) => state.markets);
  useEffect(() => {
    let marketReq = {
      fromDate: "",
      toDate: "",
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
    };
    dispatch(marketActions.getMarketList(marketReq));
  }, []);

  const handleAddOpenModal = () => {
    if (addref?.current) {
      addref.current.value = "";
    }
    setCreateModal(true);
    dispatch(userActions.clearImage());
    setFieldsMarket({});
    setErrorsMarket({});
  };

  const handleAddHideModal = (data) => {
    if (addref?.current) {
      addref.current.value = "";
    }
    setCreateModal(false);
    setFieldsMarket({});
    setErrorsMarket({});
    dispatch(userActions.clearImage());
  };

  const handleUpdateOpenModal = (data) => {
    console.log("data123", data);
    setFieldsUpdate(data);
    setNumListUpdate(data.numList);
    setUpdateModal(true);
  };

  const handleUpdateHideModal = (data) => {
    setUpdateModal(false);
    setErrorsUpdate({});
  };

  const handleViewOpenModal = (data) => {
    setRowData(data);
    setViewModal(true);
  };

  const handleViewHideModal = (data) => {
    setViewModal(false);
  };

  const inputChangeCreate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsMarket((prevState) => ({ ...prevState, [name]: value }));
    setErrorsMarket((prevState) => ({ ...prevState, [name]: "" }));
  };

  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsUpdate((prevState) => ({ ...prevState, [name]: value }));
    setErrorsUpdate((prevState) => ({ ...prevState, [name]: "" }));
  };

  let selector = useSelector((state) => state);
  const createMarketSubmit = (e) => {
    let { users } = selector ? selector : {};
    let { fileData, loading } = users ? users : {};
    e.preventDefault();
    if (handleValidationCreateMarket()) {
      let marketPageRefresh = {
        fromDate: "",
        toDate: "",
        keyWord: "",
        sortBy: "",
        sortOrder: "",
        pageNo: pageNo,
        size: size,
      };

      const { name, point, shortName } = fieldsMarket;
      dispatch(
        marketActions.createMarket(
          {
            name: name && name.trim(),
            image: fileData || " ",
            shortName: shortName && shortName.trim(),
            point: point,
            isOpenClose: true,
            numList: ["000", 111, 222, 333, 444, 555, 666, 777, 888, 999],
          },
          setCreateModal,
          marketPageRefresh
        )
      );
    }
  };

  const updateMarketSubmit = (e) => {
    e.preventDefault();
    if (handleValidationUpdateMarket()) {
      let marketPageRefresh = {
        fromDate: "",
        toDate: "",
        keyWord: "",
        sortBy: "",
        sortOrder: "",
        pageNo: pageNo,
        size: size,
      };

      const { name, shortName, _id } = fieldsUpdate;
      dispatch(
        marketActions.updateMarket(
          {
            name: name,
            image:
              "https://res.cloudinary.com/dnd2isyjo/image/upload/v1712923995/your_folder_name/cmbpkevpflbvf9l0c6v0.png",
            shortName: shortName,
            id: _id,
          },
          setUpdateModal,
          marketPageRefresh
        )
      );
    }
  };

  const handleValidationUpdateMarket = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsUpdate.name || fieldsUpdate.name.trim() === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (!fieldsUpdate.shortName || fieldsUpdate.shortName.trim() === "") {
      formIsValid = false;
      errors.shortName = DashboardJSON.ErrorMsg.shortName;
    }

    setErrorsUpdate(errors);
    return formIsValid;
  };

  const handleValidationCreateMarket = () => {
    let formIsValid = true;
    let errors = {};
    let { users } = selector ? selector : {};
    let { fileData, loading } = users ? users : {};

    if (!fieldsMarket.name || fieldsMarket.name.trim() === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (!fieldsMarket.shortName || fieldsMarket.shortName.trim() === "") {
      formIsValid = false;
      errors.shortName = DashboardJSON.ErrorMsg.shortName;
    }

    if (!fieldsMarket.point || fieldsMarket.point.trim() === "") {
      formIsValid = false;
      errors.point = DashboardJSON.ErrorMsg.point;
    }
    if (!fileData || fileData.trim() === "") {
      formIsValid = false;
      errors.img = "Please upload image";
    }

    setErrorsMarket(errors);
    return formIsValid;
  };

  const disableMarket = (data) => {
    console.log("data222222222", data);

    let marketIdReq = {
      id: data._id,
    };
    let marketPageRefresh = {
      fromDate: "",
      toDate: "",
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
    };
    confirmAlert({
      title: "Confirm to disable Market?",
      message: `Are you sure you want to disable ${data.name}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(
              marketActions.updateMarketStatus(marketIdReq, marketPageRefresh)
            ),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteMarket = (data) => {
    let marketIdReq = {
      id: data._id,
    };

    let marketPageRefresh = {
      fromDate: "",
      toDate: "",
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
    };

    confirmAlert({
      title: "Confirm to Delete?",
      message: `Are you sure you want to delete ${data.name}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(
              marketActions.deleteMarket(marketIdReq, marketPageRefresh)
            ),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleFile = (event) => {
    console.log("handleFileevent", event);

    // this.setState({ selectedFile: event.target.files[0] });
    setSelectedFile(event.target.files[0]);

    if (event.target.files[0]) {
      dispatch(userActions.uploadImage(event));
      setErrorsMarket((prevState) => ({ ...prevState, img: "" }));
    } else {
      console.log("No File To Upload!");
    }
  };

  const addContentField = () => {
    // Update numList state by adding an empty string
    setNumListUpdate((prevNumList) => [...prevNumList, ""]);
  };

  const handlePageClick = (data) => {
    console.log("data_page__page__page__page__page_???", data);
    let offset = Math.ceil(data.selected * size);
    setOffset(offset);
    setPageNo(data.selected + 1);
    let pageReq = {
      fromDate: "",
      toDate: "",
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: data.selected + 1,
      size: size,
    };

    dispatch(marketActions.getMarketList(pageReq));
  };

  const handleEditMarket = (url, element) => {
    navigate(url + element._id);
  };

  const handleViewMore = (element) => {
    setViewModal((prev) => !prev);
    setRowData(element);
  };

  return (
    <>
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    {/* <div className="mx-auto max-w-screen-3xl">
                      <section className="bg-white rounded-lg shadow">

                        <p className="text-2xl font-bold py-6 text-center">
                          Market Management
                        </p>



                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 p-6">
                          {marketList && marketList.length > 0 ? (
                            marketList
                              .filter((e) => !e.isStarLine && !e.isJodi)
                              .map((item, index) => (
                                <div
                                  key={index}
                                  className="bg-white shadow-lg hover:shadow-green-200 border rounded-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300"
                                >
                                  <p className="text-gray-500 text-sm font-semibold  p-4">
                                    S.No: {offset + index + 1}
                                  </p>

                                  <img
                                    src={item.image}
                                    alt={item.title}
                                    className=" h-48 w-40  mx-auto my-5 relative"
                                  />

                                  <div className="p-4 flex justify-center gap-10 place-items-center">
                                    <h3 className="mt-2 text-lg font-semibold text-gray-800">
                                      {item && item.name ? item.name : "-"}
                                    </h3>
                                    <button className="text-blue-600 hover:text-blue-800">
                                      <FaEdit
                                        className="text-2xl mt-1"
                                        title="Edit"
                                        onClick={() =>
                                          handleEditMarket(
                                            "/app/EditMarket/",
                                            item
                                          )
                                        }
                                      />
                                    </button>
                                  </div>
                                </div>
                              ))
                          ) : (
                            <p>No items found</p>
                          )}
                        </div>
                      </section>

                    </div> */}
                    {/* <div className="mx-auto max-w-screen-3xl mt-4">
                      <section className="bg-white rounded-lg shadow-xl border">
                        <p className="text-2xl font-bold py-6 text-center">
                          StarLine Market Management
                        </p>

                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 p-6">
                          {marketList && marketList.length > 0 ? (
                            marketList
                              .filter((e) => e.isStarLine)
                              .map((item, index) => (
                                <div
                                  key={index}
                                  className="bg-white shadow-lg hover:shadow-green-200 border rounded-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300"
                                >
                                  <p className="text-gray-500 text-sm font-semibold  p-4">
                                    S.No: {offset + index + 1}
                                  </p>

                                  <img
                                    src={item.image}
                                    alt={item.title}
                                    className=" h-48 w-40  mx-auto my-5 relative"
                                  />

                                  <div className="p-4 flex justify-center gap-10 place-items-center">
                                    <h3 className="mt-2 text-lg font-semibold text-gray-800">
                                      {item && item.name ? item.name : "-"}
                                    </h3>
                                    <button className="text-blue-600 hover:text-blue-800">
                                      <FaEdit
                                        className="text-2xl mt-1"
                                        title="Edit"
                                        onClick={() =>
                                          handleEditMarket(
                                            "/app/EditMarket/",
                                            item
                                          )
                                        }
                                      />
                                    </button>
                                  </div>
                                </div>
                              ))
                          ) : (
                            <p>No items found</p>
                          )}
                        </div>
                      </section>

                    </div> */}

                    <div className="mx-auto max-w-screen-3xl mt-4">
                      <section className="  my-4 bg-white rounded-lg shadow-xl border">
                        <p className="text-2xl font-bold py-6 text-center">
                          JackPot Market Management
                        </p>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 p-6">
                          {marketList && marketList.length > 0 ? (
                            marketList
                              .filter((e) => e.isJodi)
                              .map((item, index) => (
                                <div
                                  key={index}
                                  className="bg-white shadow-lg hover:shadow-green-200 border rounded-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300"
                                >
                                  <p className="text-gray-500 text-sm font-semibold  p-4">
                                    S.No: {offset + index + 1}
                                  </p>

                                  <img
                                    src={item.image}
                                    alt={item.title}
                                    className=" h-48 w-40  mx-auto my-5 relative"
                                  />

                                  <div className="p-4 flex justify-center gap-10 place-items-center">
                                    <h3 className="mt-2 text-lg font-semibold text-gray-800">
                                      {item && item.name ? item.name : "-"}
                                    </h3>
                                    <button className="text-blue-600 hover:text-blue-800">
                                      <FaEdit
                                        className="text-2xl mt-1"
                                        title="Edit"
                                        onClick={() =>
                                          handleEditMarket(
                                            "/app/EditMarket/",
                                            item
                                          )
                                        }
                                      />
                                    </button>
                                  </div>
                                </div>
                              ))
                          ) : (
                            <p>No items found</p>
                          )}
                        </div>
                      </section>
                    </div>
                  </div>

                  {/* {
                    isMobile ?
                      <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          marketTotal && marketTotal > 10 ?
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={marketTotal / size}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          marketTotal && marketTotal > 10 ?
                            <ReactPaginate
                              previousLabel={'Previous'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={marketTotal / size}
                              marginPagesDisplayed={3}
                              pageRangeDisplayed={3}
                              onPageChange={handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav>
                  } */}
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateMarketModal
        createModal={createModal}
        handleAddHideModal={handleAddHideModal}
        handleFile={handleFile}
        inputChangeCreate={inputChangeCreate}
        fieldsMarket={fieldsMarket}
        errorsMarket={errorsMarket}
        createMarketSubmit={createMarketSubmit}
        addref={addref}
      />

      <UpdateMarketModal
        updateModal={updateModal}
        handleUpdateHideModal={handleUpdateHideModal}
        handleFile={handleFile}
        inputChange={inputChange}
        fieldsUpdate={fieldsUpdate}
        errorsUpdate={errorsUpdate}
        addContentField={addContentField}
        updateMarketSubmit={updateMarketSubmit}
      />

      <ViewMarketModal
        viewModal={viewModal}
        handleViewHideModal={handleViewHideModal}
        rowData={rowData}
      />
    </>
  );
};

export default JackpotMarketMangment;

