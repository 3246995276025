import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { FaHome } from "react-icons/fa";
import { MdNotificationsNone } from "react-icons/md";
import { FaUserGear } from "react-icons/fa6";
import { LuGamepad2 } from "react-icons/lu";
import { TbReportAnalytics } from "react-icons/tb";
// import { IoMdMenu } from "react-icons/io";
import { GiAngelWings } from "react-icons/gi";
import { TbTargetArrow } from "react-icons/tb";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdKeyboardArrowRight } from "react-icons/md";
import { BsBank2 } from "react-icons/bs";
import profitTowin from "./profitTowin.png";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../_actions";
import { GiCardJackSpades } from "react-icons/gi";
import { PiShootingStarFill } from "react-icons/pi";
import { MdChevronRight } from "react-icons/md";
import { MdOutlineSettingsSuggest } from "react-icons/md";
import { TfiWallet } from "react-icons/tfi";



function Sidebar({ SidebarJSON, navbarOpen, setNavbarOpen }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);

  const [showSection, setShowSection] = React.useState("");

  // const onClickMenu = (url) => {
  //     history.push(url);
  //     setNavbarOpen(!navbarOpen)
  //   }

  const onClickMenu = (url) => {
    navigate(url);
    setTimeout(() => {
      // setNavbarOpen(!navbarOpen);
    }, 150);
  };

  const handleNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };

  let [localStoragedata, setlocalStoragedata] = useState();
  useEffect(() => {
    const stateData = window.sessionStorage.getItem("adminuser");
    dispatch(userActions.getAdminProfile());
    setlocalStoragedata(JSON.parse(stateData));
  }, []);

  let { users } = selector ? selector : {};
  let { adminProfileDetails } = users ? users : {};
  let { adminData } = adminProfileDetails ? adminProfileDetails : {};
  let { access } = adminData ? adminData : {};

  console.log("localStoragedat----->>", access);

  let UM =
    (localStoragedata && localStoragedata?.roleId == 0) ||
    (access && access.length > 0 && access.includes("User Management"));
  let GM =
    (localStoragedata && localStoragedata?.roleId == 0) ||
    (access && access.length > 0 && access.includes("Game Management"));
  let SM =
    (localStoragedata && localStoragedata?.roleId == 0) ||
    (access && access.length > 0 && access.includes("Setting Management"));
  let WM =
    (localStoragedata && localStoragedata?.roleId == 0) ||
    (access && access.length > 0 && access.includes("Wallet Management"));
  let RM =
    (localStoragedata && localStoragedata?.roleId == 0) ||
    (access && access.length > 0 && access.includes("Report Management"));

  return (
    <>
      <button
        className={` ${navbarOpen === true ? "hidden" : "flex"
          } absolute top-0 left-0 px-5 items-center justify-center border-r border-gray-200 text-white focus:outline-none focus:bg-gray-800 focus:text-gray-600 z-50 h-20 `}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        <svg
          className="h-6 w-6"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16M4 18h7"
          ></path>
        </svg>
      </button>
      {/* lg:flex lg:static  */}
      <div
        className={`${navbarOpen === false ? "hidden" : "flex"
          } fixed left-0 inset-y-0   w-72 z-50 flex-col`}
      >
        <div className="flex flex-col w-72 relative flex-1 h-full max-h-full">
          {/* onClick={() => onClickMenu('/app/dashboard')} */}

          <div
            className={` left-0 inset-y-0  cursor-pointer border-r relative flex items-center h-[81px] flex-shrink-0 px-4 bg-[#0F9707] border-b border-gray-200 w-full z-50`}
          >
            <div className=" flex  justify-center gap-2 items-center">
              <img
                src='/imgpsh_fullsize_anim (6).png'
                alt=""
                className=" relative flex justify-center scale-150 items-center  md:ml-6"
                width={"90px"}
              />
            </div>

            <div className="  items-end absolute right-3 ">
              <GiHamburgerMenu
                onClick={handleNavbar}
                className=" text-white text-xl  block"
              />
            </div>
          </div>

          <nav className="overflow-y-auto bg-[white] flex flex-col flex-wrap items-center justify-between relative  w-72 z-10 px-3  text-[14px] h-full overflow-auto">
            <div className="px-0 flex flex-col flex-wrap  pt-2 items-center w-full mx-auto flex-1">
              <div className={"relative z-40 flex-1 w-full space-y-1"}>
                <div className="">
                  <span
                    onClick={() =>
                      onClickMenu("/app/dashboard", handleNavbar())
                    }
                    className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2  transition ease-in-out duration-150 hover:bg-green-200  ${location.pathname === "/app/dashboard"
                      ? "text-white bg-[#0F9707] rounded-md"
                      : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                      }`}
                  >
                    <span className="group-hover:text-[#0F9707]">
                      <FaHome size={20} />
                    </span>
                    <span className="text-sm  lg:font-lg">Dashboard</span>
                  </span>
                  {UM && (
                    <span
                      onClick={() => onClickMenu("/app/user", handleNavbar())}
                      className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 hover:bg-green-200 text-sm transition ease-in-out duration-150   ${location.pathname === "/app/user" ||
                        location.pathname.includes("/app/user/") ||
                        location.pathname.includes("/app/EditUser/")
                        ? "text-white bg-[#0F9707] rounded-md"
                        : "text-black"
                        }`}
                    >
                      <span
                        className={`text-black  rounded-md   hover:text-[#0F9707] ${location.pathname === "/app/user" ? "text-white" : ""
                          }`}
                      >
                        <FaUserGear size={20} />
                      </span>
                      <span className="text-sm  lg:font-lg">
                        User Management
                      </span>
                    </span>
                  )}
                  {/* {localStoragedata && localStoragedata.roleId == 0 &&
                                        <span onClick={() => onClickMenu('/app/declareResult', handleNavbar())} className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/declareResult" ? "text-white bg-[#0F9707] rounded-md" : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"}`}>
                                            <span className="group-hover:text-[#0F9707]"><FaRegNewspaper size={20} /></span>
                                            <span className="text-sm  lg:font-lg">Declare Result</span>
                                        </span>} */}

                  {/* {localStoragedata && localStoragedata.roleId == 0 &&
                                        <span onClick={() => onClickMenu('/app/declareResultFatafat', handleNavbar())} className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/declareResultFatafat" ? "text-white bg-[#0F9707] rounded-md" : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"}`}>
                                            <span className="group-hover:text-[#0F9707]"><FaRegNewspaper size={20} /></span>
                                            <span className="text-sm  lg:font-lg">Declare FataFat Result</span>
                                        </span>} */}

                  {localStoragedata && localStoragedata.roleId == 0 && (
                    <span
                      onClick={() =>
                        onClickMenu("/app/winnerList", handleNavbar())
                      }
                      className={`cursor-pointer h-9 items-center flex space-x-2 px-1 hover:bg-green-200 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/winnerList"
                        ? "text-white bg-[#0F9707] rounded-md"
                        : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                        }`}
                    >
                      <span className="group-hover:text-[#0F9707]">
                        <GiAngelWings size={20} />
                      </span>
                      <span className="text-sm  lg:font-lg">Winner List</span>
                    </span>
                  )}
                  <h2 className=" w-full h-[2px] bg-[white] my-1"></h2>

                  {/* <span onClick={() => onClickMenu('/app/appsetting',handleNavbar())} className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/appsetting" ? "text-white bg-[#0F9707] rounded-md" : "text-black bg-[white] rounded-md   hover:text-[#0F9707]" }`}>
                                        <span className="group-hover:text-[#0F9707]"><MdOutlineCreditCard size={20} /></span>
                                        <span className="text-sm  lg:font-lg">App Setting Management</span>
                                    </span> */}

                  {/* {localStoragedata && localStoragedata.roleId == 0 && <span onClick={() => onClickMenu('/app/bankdetails', handleNavbar())} className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/bankdetails" ? "text-white bg-[#0F9707] rounded-md" : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"}`}>
                                        <span className="group-hover:text-[#744939]"><BsBank2 size={20} /></span>
                                        <span className="text-sm  lg:font-lg">Bank Management</span>
                                    </span>} */}
                  {/* SUPER_ADMIN: 0,
                ADMIN: 1,
                SUPER_MASTER: 2,
                MASTER: 3,
                AGENT: 4,
                OPERATOR: 20,
                CLIENT: 100 */}

                  {localStoragedata && localStoragedata.roleId == 0 && (
                    <div className="border-b-2  border-green-600">
                      <span
                        onClick={() =>
                          onClickMenu("/app/downsideUser", handleNavbar())
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 mb-1 rounded-md hover:bg-green-200   text-sm transition ease-in-out duration-150   ${location.pathname === "/app/downsideUser" ||
                          location.pathname.includes("/app/downsideUser/") ||
                          location.pathname.includes("/app/EditdownsideUser/")
                          ? "text-white bg-[#0F9707] rounded-md"
                          : "text-black"
                          }`}
                      >
                        <span className="text-black  rounded-md   hover:text-[#0F9707]">
                          <MdOutlineAdminPanelSettings
                            size={20}
                            className={` ${location.pathname === "/app/downsideUser" ||
                              location.pathname.includes("/app/downsideUser/") ||
                              location.pathname.includes("/app/EditdownsideUser/")
                              ? "text-white bg-[#0F9707] rounded-md"
                              : "text-black"
                              }`}
                          />
                        </span>
                        <span className="text-sm  lg:font-lg">ADMIN</span>
                      </span>
                    </div>

                    // <>
                    //   <span
                    //     onClick={() =>
                    //       onClickMenu("/app/downsideUser", handleNavbar())
                    //     }
                    //     className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                    //       location.pathname === "/app/downsideUser"
                    //         ? "text-white bg-[#0F9707] rounded-md"
                    //         : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                    //     }`}
                    //   >
                    //     <span className="text-sm  lg:font-lg">ADMIN</span>
                    //   </span>
                    // </>
                  )}
                  {/* {localStoragedata && localStoragedata.roleId == 1 && (
                      <span className="text-sm  lg:font-lg">SUPER MASTER</span>
                    )}
                    {localStoragedata && localStoragedata.roleId == 2 && (
                      <span className="text-sm  lg:font-lg">MASTER</span>
                    )}
                    {localStoragedata && localStoragedata.roleId == 3 && (
                      <span className="text-sm  lg:font-lg">AGENT</span>
                    )}
                    {localStoragedata && localStoragedata.roleId == 4 && (
                      <span className="text-sm  lg:font-lg">OPERATOR</span>
                    )}
                    {localStoragedata && localStoragedata.roleId == 20 && (
                      <span className="text-sm  lg:font-lg">CLIENT</span>
                    )} */}

                  <span
                    onClick={() =>
                      onClickMenu("/app/notification", handleNavbar())
                    }
                    className={`cursor-pointer h-9 items-center flex space-x-3 px-1 py-2 text-sm transition ease-in-out duration-150  hover:bg-green-200  ${location.pathname === "/app/notification"
                      ? "text-white bg-[#0F9707] rounded-md"
                      : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                      }`}
                  >
                    <span className="group-hover:text-[#0F9707]">
                      <MdNotificationsNone size={20} />
                    </span>
                    <span className="text-sm  lg:font-lg">
                      Notification Managemnt
                    </span>
                  </span>

                  {SM && (
                    <div className="cursor-pointer group">
                      {showSection === "settingManagement" ? (
                        <span
                          onClick={() => setShowSection("")}
                          className="flex items-center justify-between px-1 py-2 space-x-2 text-sm text-black transition duration-150  ease-in-out cursor-pointer h-9"
                        >
                          <div className="flex  items-center px-1 py-2 space-x-2 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9">
                            <span className="group-hover:text-[#0F9707]">
                              <MdOutlineSettingsSuggest size={20} />
                            </span>
                            <span className="text-sm group-hover:text-[#0F9707]  lg:font-lg">
                              Setting Management
                            </span>
                          </div>

                          {showSection === "settingManagement" ? (
                            <HiChevronUp className="group-hover:text-[#0F9707] text-lg" />
                          ) : (
                            <MdChevronRight className="group-hover:text-[#0F9707]" />
                          )}
                        </span>
                      ) : (
                        <span
                          onClick={() => setShowSection("settingManagement")}
                          className="flex items-center hover:bg-green-200 rounded-md justify-between px-1 py-2 space-x-2 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                        >
                          <div className="flex items-center px-1 py-2 space-x-2 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9">
                            <span className="group-hover:text-[#0F9707]">
                              <MdOutlineSettingsSuggest size={20} />

                            </span>
                            <span className="text-sm text-black  rounded-md   hover:text-[#0F9707]  lg:font-lg">
                              Setting Management
                            </span>
                          </div>

                          {showSection === "settingManagement" ? (
                            <HiChevronUp className="group-hover:text-[#0F9707]" />
                          ) : (
                            <MdChevronRight className="group-hover:text-[#0F9707] text-lg" />
                          )}
                        </span>
                      )}
                      {showSection === "settingManagement" ? (
                        <div className="  flex flex-col pl-10">
                          <span
                            onClick={() =>
                              onClickMenu("/app/appsetting", handleNavbar())
                            }
                            className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/appsetting"
                              ? "text-white bg-[#0F9707] rounded-md"
                              : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                              }`}
                          >
                            <MdKeyboardArrowRight />{" "}
                            <span className="text-sm  lg:font-lg">
                              App Setting
                            </span>
                          </span>
                          <span
                            onClick={() =>
                              onClickMenu("/app/slider", handleNavbar())
                            }
                            className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/slider"
                              ? "text-white bg-[#0F9707] rounded-md"
                              : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                              }`}
                          >
                            <MdKeyboardArrowRight />{" "}
                            <span className="text-sm  lg:font-lg">
                              Slider Setting
                            </span>
                          </span>
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>

                <div>
                  {GM && (
                    <div className="cursor-pointer group">
                      {showSection === "starlinegameMangment" ? (
                        <span
                          onClick={() => setShowSection("")}
                          className="flex justify-between items-center px-1 py-2 space-x-2 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                        >
                          <div className="flex items-center px-1 py-2 space-x-2 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9">
                            <span className="group-hover:text-[#0F9707]">
                              <PiShootingStarFill size={20} />

                            </span>
                            <span className="text-sm group-hover:text-[#0F970starline-point-history7]  lg:font-lg">
                              Starline Game  Management
                            </span>
                          </div>

                          {showSection === "starlinegameMangment" ? (
                            <HiChevronUp className="group-hover:text-[#0F9707] text-lg" />
                          ) : (
                            <MdChevronRight className="group-hover:text-[#0F9707]" />
                          )}
                        </span>
                      ) : (
                        <span
                          onClick={() => setShowSection("starlinegameMangment")}
                          className="flex items-center justify-between px-1 py-2 hover:bg-green-200 rounded-md space-x-2  text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                        >
                          <div className="flex pic items-center  px-1 py-2 space-x-2 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9">
                            <span className="group-hover:text-[#0F9707]">
                              <PiShootingStarFill size={20} />

                            </span>
                            <span className="text-sm text-black rounded-md   hover:text-[#0F9707]  lg:font-lg">
                              Starline Game Management
                            </span>
                          </div>

                          {showSection === "starlinegameMangment" ? (
                            <HiChevronUp className="group-hover:text-[#0F9707]" />
                          ) : (
                            <MdChevronRight className="group-hover:text-[#0F9707] text-lg" />
                          )}
                        </span>
                      )}
                      {showSection === "starlinegameMangment" ? (
                        <div className="  flex flex-col pl-10">
                          <span
                            onClick={() =>
                              onClickMenu("/app/Brandgame", handleNavbar())
                            }
                            className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/Brandgame"
                              ? "text-white bg-[#0F9707] rounded-md"
                              : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                              }`}
                          >
                            {/* <span className="group-hover:text-[#0F9707]"><LuGamepad2 size={20} /></span> */}
                            <MdKeyboardArrowRight />{" "}
                            <span className="text-sm  lg:font-lg">
                              Starline Game
                            </span>
                          </span>
                          <span
                            onClick={() =>
                              onClickMenu(
                                "/app/declareResultFatafat",
                                handleNavbar()
                              )
                            }
                            className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/declareResultFatafat"
                              ? "bg-[white] text-black  rounded-lg "
                              : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                              }`}
                          >
                            <MdKeyboardArrowRight />{" "}
                            <span className="text-sm  lg:font-lg">
                              Starline Result Declare
                            </span>
                          </span>



                          <span
                            onClick={() =>
                              onClickMenu("/app/starline-point-history", handleNavbar())
                            }
                            className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/starline-point-history"
                              ? "text-white bg-[#0F9707] rounded-md"
                              : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                              }`}
                          >
                            {/* <span className="group-hover:text-[#0F9707]"><LuGamepad2 size={20} /></span> */}
                            <MdKeyboardArrowRight />{" "}
                            <span className="text-sm  lg:font-lg">
                              Starline Point History{" "}
                            </span>
                          </span>
                          <span
                            onClick={() =>
                              onClickMenu(
                                "/app/userbidhistoryStarLine",
                                handleNavbar()
                              )
                            }
                            className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/userbidhistoryStarLine"
                              ? "bg-[#0F9707] text-white  rounded-lg "
                              : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                              }`}
                          >
                            <MdKeyboardArrowRight />{" "}
                            <span className="text-sm  lg:font-lg">
                              User Bid Starline
                            </span>
                          </span>


                          <span
                            onClick={() =>
                              onClickMenu(
                                "/app/starlineMarketMangment",
                                handleNavbar()
                              )
                            }
                            className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/starlineMarketMangment"
                              ? "bg-[#0F9707] text-white  rounded-lg "
                              : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                              }`}
                          >
                            <MdKeyboardArrowRight />{" "}
                            <span className="text-sm  lg:font-lg">
                              Starline Market Management
                            </span>
                          </span>


                        </div>
                      ) : null}
                    </div>
                  )}
                </div>





                <div>
                  {GM && (
                    <div className="cursor-pointer group">
                      {showSection === "jackpotgameMangment" ? (
                        <span
                          onClick={() => setShowSection("")}
                          className="flex justify-between items-center px-1 py-2 space-x-2 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                        >
                          <div className="flex items-center px-1 py-2 space-x-2 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9">
                            <span className="group-hover:text-[#0F9707]">
                              <GiCardJackSpades size={20} />
                            </span>
                            <span className="text-sm group-hover:text-[#0F9707]  lg:font-lg">
                              Jackpot Game  Management
                            </span>
                          </div>

                          {showSection === "jackpotgameMangment" ? (
                            <HiChevronUp className="group-hover:text-[#0F9707] text-lg" />
                          ) : (
                            <MdChevronRight className="group-hover:text-[#0F9707]" />
                          )}
                        </span>
                      ) : (
                        <span
                          onClick={() => setShowSection("jackpotgameMangment")}
                          className="flex justify-between items-center px-1 py-2 space-x-2 hover:bg-green-200 rounded-md text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                        >
                          <div className="flex justify-between items-center px-1 py-2 space-x-2 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9">
                            <span className="group-hover:text-[#0F9707]">
                              <GiCardJackSpades size={20} />

                            </span>
                            <span className="text-sm text-black  rounded-md   hover:text-[#0F9707]  lg:font-lg">
                              Jackpot Game Management
                            </span>
                          </div>

                          {showSection === "jackpotgameMangment" ? (
                            <HiChevronUp className="group-hover:text-[#0F9707]" />
                          ) : (
                            <MdChevronRight className="group-hover:text-[#0F9707] text-lg" />
                          )}
                        </span>
                      )}
                      {showSection === "jackpotgameMangment" ? (
                        <div className="  flex flex-col pl-10">
                          <span
                            onClick={() =>
                              onClickMenu("/app/isJackPot", handleNavbar())
                            }
                            className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/isJackPot"
                              ? "text-white bg-[#0F9707] rounded-md"
                              : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                              }`}
                          >
                            {/* <span className="group-hover:text-[#0F9707]"><LuGamepad2 size={20} /></span> */}
                            <MdKeyboardArrowRight />{" "}
                            <span className="text-sm  lg:font-lg">
                              JackPot Game{" "}
                            </span>
                          </span>
                          <span
                            onClick={() =>
                              onClickMenu(
                                "/app/declareResultJackPot",
                                handleNavbar()
                              )
                            }
                            className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/declareResultJackPot"
                              ? "bg-[white] text-black  rounded-lg "
                              : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                              }`}
                          >
                            <MdKeyboardArrowRight />{" "}
                            <span className="text-sm  lg:font-lg">
                              JackPot Result Declare
                            </span>
                          </span>
                          <span
                            onClick={() =>
                              onClickMenu(
                                "/app/userbidhistoryJackPot",
                                handleNavbar()
                              )
                            }
                            className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/userbidhistoryJackPot"
                              ? "bg-[#0F9707] text-white  rounded-lg "
                              : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                              }`}
                          >
                            <MdKeyboardArrowRight />
                            <span className="text-sm  lg:font-lg">
                              User Bid JackPot
                            </span>
                          </span>


                          <span
                            onClick={() =>
                              onClickMenu(
                                "/app/jackpot-point-history",
                                handleNavbar()
                              )
                            }
                            className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/jackpot-point-history"
                              ? "bg-[#0F9707] text-white  rounded-lg "
                              : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                              }`}
                          >
                            <MdKeyboardArrowRight />
                            <span className="text-sm  lg:font-lg">
                              Jackpot Point History
                            </span>
                          </span>

                          <span
                            onClick={() =>
                              onClickMenu(
                                "/app/jackpotMarketMangment",
                                handleNavbar()
                              )
                            }
                            className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/jackpotMarketMangment"
                              ? "bg-[#0F9707] text-white  rounded-lg "
                              : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                              }`}
                          >
                            <MdKeyboardArrowRight />{" "}
                            <span className="text-sm  lg:font-lg">
                              JackPot Market Mangment
                            </span>
                          </span>


                        </div>
                      ) : null}
                    </div>
                  )}
                </div>






















                {GM && (
                  <div className="cursor-pointer group border-b-2 border-green-500">
                    {showSection === "game" ? (
                      <span
                        onClick={() => setShowSection("")}
                        className="flex justify-between items-center px-1 py-2 space-x-2 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                      >
                        <div className="flex items-center px-1 py-2 space-x-2 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9">
                          <span className="group-hover:text-[#0F9707]">
                            <LuGamepad2 size={20} />
                          </span>
                          <span className="text-sm group-hover:text-[#0F9707]  lg:font-lg">
                            Game Management
                          </span>
                        </div>

                        {showSection === "game" ? (
                          <HiChevronUp className="group-hover:text-[#0F9707] text-lg" />
                        ) : (
                          <HiChevronDown className="group-hover:text-[#0F9707]" />
                        )}
                      </span>
                    ) : (
                      <span
                        onClick={() => setShowSection("game")}
                        className="flex justify-between items-center px-1 py-2 hover:bg-green-200 rounded-md mb-2 space-x-2 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                      >
                        <div className="flex  items-center px-1 py-2 space-x-2 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9">
                          <span className="group-hover:text-[#0F9707]">
                            <LuGamepad2 size={20} />{" "}
                          </span>
                          <span className="text-sm group-hover:text-[#0F9707]  lg:font-lg">
                            Game Management
                          </span>
                        </div>

                        {showSection === "game" ? (
                          <HiChevronUp className="group-hover:text-[#0F9707]" />
                        ) : (
                          <MdChevronRight className="group-hover:text-[#0F9707] clear-start text-lg" />
                        )}
                      </span>
                    )}
                    {showSection === "game" ? (
                      <div className=" mb-5  flex flex-col pl-10">
                        {/*    <span
                          onClick={() =>
                            onClickMenu("/app/brand", handleNavbar())
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                            location.pathname === "/app/brand"
                              ? "text-white bg-[#0F9707] rounded-md"
                              : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                          }`}
                        >
                         
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  lg:font-lg">Starline </span>
                        </span>*/}

                        {/* <span onClick={() => onClickMenu('/app/Kolkatagame', handleNavbar())} className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/Kolkatagame" ? "text-white bg-[#0F9707] rounded-md" : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"}`}>
                                               
                                                <MdKeyboardArrowRight /> <span className="text-sm  lg:font-lg">Kolkata Game </span>
                                            </span> */}

                        <span
                          onClick={() =>
                            onClickMenu("/app/game", handleNavbar())
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/game"
                            ? "text-white bg-[#0F9707] rounded-md"
                            : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                            }`}
                        >
                          {/* <span className="group-hover:text-[#0F9707]"><LuGamepad2 size={20} /></span> */}
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  lg:font-lg">Game </span>
                        </span>

                        <span
                          onClick={() =>
                            onClickMenu("/app/declareResult", handleNavbar())
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/declareResult"
                            ? "bg-[white] text-black  rounded-lg "
                            : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                            }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  lg:font-lg">
                            Game Result Game
                          </span>
                        </span>

                        <span
                          onClick={() =>
                            onClickMenu("/app/userbidhistory", handleNavbar())
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/userbidhistory"
                            ? "bg-[#0F9707] text-white  rounded-lg "
                            : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                            }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  lg:font-lg">
                            User Bid History
                          </span>
                        </span>


                        <span
                          onClick={() =>
                            onClickMenu("/app/betHistory", handleNavbar())
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/betHistory"
                            ? "bg-[#0F9707] text-white  rounded-lg "
                            : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                            }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  lg:font-lg">
                            Bid Point History
                          </span>
                        </span>


                        <span
                          onClick={() =>
                            onClickMenu("/app/market", handleNavbar())
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/market" ||
                            location.pathname.includes("app/EditMarket/")
                            ? "text-white bg-[#0F9707] rounded-md"
                            : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                            }`}
                        >
                          {/* <span className="group-hover:text-[#0F9707]"><FaShoppingBasket size={20} /></span> */}
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  lg:font-lg">
                            Market Management
                          </span>
                        </span>
                      </div>
                    ) : null}
                  </div>
                )}

                {/* {localStoragedata && localStoragedata.roleId == 0 && (
                  <div className="cursor-pointer group">
                    {showSection === "resultDeclare" ? (
                      <span
                        onClick={() => setShowSection("")}
                        className="flex items-center px-1 py-2 space-x-2 text-sm text-black bg-[white] rounded-sm transition duration-150 ease-in-out cursor-pointer h-9"
                      >
                        <span className="group-hover:text-[#0F9707]">
                          <TbTargetArrow size={20} />
                        </span>
                        <span className="text-sm group-hover:text-[#0F9707]  lg:font-lg">
                          Result Management
                        </span>
                        {showSection === "resultDeclare" ? (
                          <HiChevronUp className="group-hover:text-[#0F9707]" />
                        ) : (
                          <HiChevronDown className="group-hover:text-[#0F9707]" />
                        )}
                      </span>
                    ) : (
                      <span
                        onClick={() => setShowSection("resultDeclare")}
                        className="flex items-center px-1 py-2 space-x-2 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                      >
                        <span className="group-hover:text-[#0F9707]">
                          <TbTargetArrow size={20} />{" "}
                        </span>
                        <span className="text-sm group-hover:text-[#0F9707]  lg:font-lg">
                          Result Management
                        </span>
                        {showSection === "resultDeclare" ? (
                          <HiChevronUp className="group-hover:text-[#0F9707]" />
                        ) : (
                          <HiChevronDown className="group-hover:text-[#0F9707]" />
                        )}
                      </span>
                    )}
                    {showSection === "resultDeclare" ? (
                      <div className=" mb-5  flex flex-col pl-10">



                      </div>
                    ) : null}
                  </div>
                )} */}

                {WM && (
                  <div className="cursor-pointer group">
                    {showSection === "wm" ? (
                      <span
                        onClick={() => setShowSection("")}
                        className="flex justify-between items-center px-1 py-2  space-x-2 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                      >
                        <div className="flex justify-between items-center px-1 py-2 space-x-2 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9">
                          <span className="group-hover:text-[#0F9707]">
                            <TfiWallet size={20} />

                          </span>
                          <span className="text-sm group-hover:text-[#0F9707]  lg:font-lg">
                            Wallet Management
                          </span>
                        </div>

                        {showSection === "wm" ? (
                          <HiChevronUp className="group-hover:text-[#0F9707] text-lg" />
                        ) : (
                          <HiChevronDown className="group-hover:text-[#0F9707]" />
                        )}
                      </span>
                    ) : (
                      <span
                        onClick={() => setShowSection("wm")}
                        className="flex justify-between items-center px-1 hover:bg-green-200 rounded-md py-2 space-x-2 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                      >
                        <div className="flex  items-center px-1 py-2 space-x-2 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9">
                          <span className="group-hover:text-[#0F9707]">
                            <TfiWallet size={20} />

                          </span>
                          <span className="text-sm group-hover:text-[#0F9707]  lg:font-lg">
                            Wallet Management
                          </span>
                        </div>

                        {showSection === "wm" ? (
                          <HiChevronUp className="group-hover:text-[#0F9707]" />
                        ) : (
                          <MdChevronRight className="group-hover:text-[#0F9707] text-lg" />
                        )}
                      </span>
                    )}
                    {showSection === "wm" ? (
                      <div className=" mb-5  flex flex-col pl-10">
                        <span
                          onClick={() =>
                            onClickMenu("/app/withdrawRequest", handleNavbar())
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150 ${location.pathname === "/app/betHistory"
                            ? "bg-[#0F9707] text-white  rounded-lg "
                            : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                            }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  lg:font-lg">
                            WithDraw Request
                          </span>
                        </span>
                        <span
                          onClick={() =>
                            onClickMenu("/app/AddFundRequest", handleNavbar())
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/betHistory"
                            ? "bg-[#0F9707] text-white  rounded-lg "
                            : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                            }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  lg:font-lg">
                            Add Fund Request
                          </span>
                        </span>
                        <span
                          onClick={() =>
                            onClickMenu("/app/addFund", handleNavbar())
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/betHistory"
                            ? "bg-[#0F9707] text-white  rounded-lg "
                            : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                            }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  lg:font-lg">
                            Add Fund (UserWallet)
                          </span>
                        </span>
                        <span
                          onClick={() =>
                            onClickMenu("/app/withdrawfund", handleNavbar())
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/betHistory"
                            ? "bg-[#0F9707] text-white  rounded-lg "
                            : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                            }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  lg:font-lg">
                            WithdrawFund (UserWallet)
                          </span>
                        </span>

                        {/* <span
                        onClick={() =>
                          onClickMenu("/app/ledger", handleNavbar())
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                          location.pathname === "/app/ledger"
                            ? "bg-[white] text-black  rounded-lg "
                            : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                        }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">
                          My Point Statement
                        </span>
                      </span> */}

                        {/* <span
                        onClick={() =>
                          onClickMenu("/app/betsledger", handleNavbar())
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                          location.pathname === "/app/betsledger"
                            ? "bg-[white] text-black  rounded-lg "
                            : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                        }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">My Ledger</span>
                      </span> */}
                      </div>
                    ) : null}
                  </div>
                )}
                {RM && (
                  <div className="cursor-pointer group">
                    {showSection === "report" ? (
                      <span
                        onClick={() => setShowSection("")}
                        className="flex justify-between items-center px-1 py-2 space-x-2 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                      >

                        <div className="flex items-center px-1 py-2 space-x-2 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9">
                          <span className="group-hover:text-[#0F9707]">
                            <TbReportAnalytics size={20} />
                          </span>
                          <span className="text-sm group-hover:text-[#0F9707]  lg:font-lg">
                            Report Management
                          </span>
                        </div>

                        {showSection === "report" ? (
                          <HiChevronUp className="group-hover:text-[#0F9707] text-lg" />
                        ) : (
                          <HiChevronDown className="group-hover:text-[#0F9707]" />
                        )}
                      </span>
                    ) : (
                      <span
                        onClick={() => setShowSection("report")}
                        className="flex justify-between items-center px-1 hover:bg-green-200 rounded-md py-2 space-x-2 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                      >
                        <div className="flex  items-center px-1 py-2 space-x-2 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9">
                          <span className="group-hover:text-[#0F9707]">
                            <TbReportAnalytics size={20} />{" "}
                          </span>
                          <span className="text-sm group-hover:text-[#0F9707]  lg:font-lg">
                            Report Management
                          </span>
                        </div>

                        {showSection === "report" ? (
                          <HiChevronUp className="group-hover:text-[#0F9707]" />
                        ) : (
                          <MdChevronRight className="group-hover:text-[#0F9707] text-lg" />
                        )}
                      </span>
                    )}
                    {showSection === "report" ? (
                      <div className=" mb-5  flex flex-col pl-10">
                    


                        <span
                          onClick={() =>
                            onClickMenu("/app/addFundReport", handleNavbar())
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/addFundReport"
                            ? "bg-[#0F9707] text-white  rounded-lg "
                            : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                            }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  lg:font-lg">
                            Add Fund Report
                          </span>
                        </span>
                        <span
                          onClick={() =>
                            onClickMenu("/app/withdrawReport", handleNavbar())
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/withdrawReport"
                            ? "bg-[#0F9707] text-white  rounded-lg "
                            : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                            }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  lg:font-lg">
                            WithDraw Report
                          </span>
                        </span>

                        <span
                        onClick={() =>
                          onClickMenu("/app/ledger", handleNavbar())
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                          location.pathname === "/app/ledger"
                            ? "bg-[white] text-black  rounded-lg "
                            : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                        }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">
                          My Point Statement
                        </span>
                      </span>

                        {/* <span
                        onClick={() =>
                          onClickMenu("/app/betsledger", handleNavbar())
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-2 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                          location.pathname === "/app/betsledger"
                            ? "bg-[white] text-black  rounded-lg "
                            : "text-black bg-[white] rounded-md   hover:text-[#0F9707]"
                        }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">My Ledger</span>
                      </span> */}
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
