import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardJSON from "./WalletManagement.json";
import { gamesActions, marketActions, walletActions } from "../../_actions";
import Select from "react-select";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ReactPaginate from "react-paginate";
import { isMobile } from "react-device-detect";
import Loader from "../../components/Loader/Loader";
import ViewGameModal from "../gameManagement/components/ViewGameModal/ViewGameModal";
import { confirmAlert } from "react-confirm-alert";

const DeclareResultFatafat = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  let [viewModal, setViewModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const [fieldsUpdate, setFieldsUpdate] = useState({});
  const [errorsUpdate, setErrorsUpdate] = useState({});
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  const [size, setSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [keyWord, setkeyWord] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");

  const today = new Date().toISOString().split("T")[0];

  const { paymentList, totalPayment } = useSelector((state) => state.wallet);
  const { allMarket } = useSelector((state) => state.markets);
  const { gamesList, gamesTotal } = useSelector((state) => state.games);

  useEffect(() => {
    let gameReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: 1,
      size: 500,
    };
    let listReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
      brandGame: true,
      JodiGame:false
    };

    dispatch(gamesActions.marketNumberInSingle());
    dispatch(gamesActions.getResultListAdmin(listReq));
    dispatch(gamesActions.getAllGames(gameReq));
    dispatch(gamesActions.marketNumberThreeDigit());
  }, [pageNo]);

  const inputChangeCreate = (e) => {
    // e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
    setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));
  };

  const createGameSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreateGame()) {
      let gamePageRefresh = {
        fromDate: fromDate,
        toDate: toDate,
        keyWord: keyWord,
        sortBy: "",
        sortOrder: "",
        pageNo: pageNo,
        size: size,
        brandGame: true,
        JodiGame:false
      };

      let obj = {
        gameId: fieldsGame?.gameId,
        betNumber: fieldsGame?.num,
        isOpen: true,
        brandGame: true,
        date: fieldsGame?.date,
      };

      dispatch(gamesActions.saveResultAdmin(obj, gamePageRefresh,ShowWinnerList2));
      // setFieldsGame({})
    }
  };

  const handleValidationUpdateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsUpdate.name || fieldsUpdate.name === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (!fieldsUpdate.shortName || fieldsUpdate.shortName === "") {
      formIsValid = false;
      errors.shortName = DashboardJSON.ErrorMsg.shortName;
    }

    if (!fieldsUpdate.openTime || fieldsUpdate.openTime === "") {
      formIsValid = false;
      errors.openTime = DashboardJSON.ErrorMsg.openTime;
    }

    if (!fieldsUpdate.closingTime || fieldsUpdate.closingTime === "") {
      formIsValid = false;
      errors.closingTime = DashboardJSON.ErrorMsg.closingTime;
    }

    if (!fieldsUpdate.priority || fieldsUpdate.priority === "") {
      formIsValid = false;
      errors.priority = DashboardJSON.ErrorMsg.priority;
    }

    if (!fieldsUpdate.backgroundColor || fieldsUpdate.backgroundColor === "") {
      formIsValid = false;
      errors.backgroundColor = DashboardJSON.ErrorMsg.backgroundColor;
    }
    console.log("errorsUpdate????", errorsUpdate);
    setErrorsUpdate(errors);
    return formIsValid;
  };

  const handleValidationCreateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsGame.date || fieldsGame.date === "") {
      formIsValid = false;
      errors.date = "Date is required";
    }

    if (!fieldsGame.gameId || fieldsGame.gameId === "") {
      formIsValid = false;
      errors.gameId = "Game is Required";
    }

    // if (!fieldsGame.isOpen || fieldsGame.isOpen === "") {
    //   formIsValid = false;
    //   errors.isOpen = "Select Sessions";
    // }

    if (!fieldsGame.num || fieldsGame.num === "") {
      formIsValid = false;
      errors.num = "Empty fields";
    }

    setErrorsGame(errors);
    return formIsValid;
  };

  const handlePageClick = (data) => {
    console.log("data_page__page__page__page__page_???", data);
    let offset = Math.ceil(data.selected * size);
    setCurrentPage(data.selected);
    setOffset(offset);
    setPageNo(data.selected + 1);
    let pageReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: data.selected + 1,
      size: size,
      brandGame: true,
    };
    dispatch(gamesActions.getAllGames(pageReq));
  };

  const ShowWinnerList = () => {
    let obj = {
      gameId: fieldsGame?.gameId,
      betNumber: fieldsGame?.num?.toString() == "000" ? "0" : fieldsGame?.num,
      isOpen: true,
      date: fieldsGame?.date,
    };
    if (handleValidationCreateGame()) {
      dispatch(gamesActions.getWinnerListByBetNumber(obj));
      setViewModal(true);
    }
  };
  const ShowWinnerList2 = () => {
    let obj = {
      gameId: fieldsGame?.gameId,
      betNumber: fieldsGame?.num,
      isOpen: true,
      brandGame: true,
      isJackPot:false,
      date: fieldsGame?.date,
    };
    if (handleValidationCreateGame()) {
      dispatch(gamesActions.resultDeclare(obj));
      // setViewModal(true)
    }
  };

  let handleViewHideModal = () => {
    setViewModal(false);
  };

  const rollback = (e) => {
    let obj = {
      gameId: e?.gameId?._id,
      closeNumber: null,
      jodiNumber: e?.jodiNumber,
      openNumber: e?.openNumber == 0 ? "000" : e?.openNumber,
      date: e && e.rDate && new Date(e.rDate).toISOString().split("T")[0],
    };

    let listReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
      brandGame: true,
      JodiGame:false
    };

    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to Rollback",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(gamesActions.rollbackResultDeclare(obj, listReq)),
        },
        {
          label: "No",
        },
      ],
    });
  };

  let { games } = selector ? selector : {};
  let {
    loading,
    gameResultList,
    gameResultTotal,
    winnerList,
    resultList,
    numberArray,
    numberList,
  } = games ? games : {};
  let optionsData =
    gamesList && gamesList.length > 0 && gamesList.filter((e) => e?.brandGame);

  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl space-y-5">
                      <div className="text-left bg-white space-y-2 rounded-lg">
                        <div className="px-6 py-4 flex items-center justify-between border-b">
                          <span className=" text-lg font-bold">
                            Save Result (Starline Game)
                          </span>
                        </div>
                        <div className="px-6 py-4 w-full">
                          <div className="block  md:flex gap-4 ">
                            <div className="w-full mt-1 shadow-sm">
                              <label
                                class="block text-gray-700 text-base mb-2 font-bold"
                                for="gamename"
                              >
                                Result Date
                              </label>
                              <input
                                className={`w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-md focus:outline-none`}
                                id="date"
                                name="date"
                                placeholder="Enter Date"
                                value={
                                  fieldsGame && fieldsGame["date"]
                                    ? fieldsGame["date"]
                                    : ""
                                }
                                max={today}
                                type="date"
                                onChange={inputChangeCreate}
                              />
                              {errorsGame && errorsGame["date"] ? (
                                <div className="text-red-600 invalid-feedback">
                                  {errorsGame["date"]}
                                </div>
                              ) : null}
                            </div>

                            <div className="w-full mt-1declareResultFatafat shadow-sm">
                              <label
                                class="block text-gray-700 text-base mb-2 whitespace-nowrap font-bold"
                                for="name"
                              >
                                Game Name
                              </label>
                              <select
                                className={`w-full capitalize px-3 py-2 text-sm bg-white border border-gray-400 rounded-md focus:outline-none`}
                                id="gameId"
                                name="gameId"
                                placeholder="name"
                                value={
                                  fieldsGame && fieldsGame["gameId"]
                                    ? fieldsGame["gameId"]
                                    : ""
                                }
                                type="text"
                                onChange={inputChangeCreate}
                              >
                                <option value="">Select Game</option>
                                {optionsData && optionsData.length > 0
                                  ? optionsData.map((element, index) => (
                                    <option
                                      value={
                                        element && element._id
                                          ? element._id
                                          : ""
                                      }
                                    >
                                      {element && element.name
                                        ? element.name
                                        : ""}
                                    </option>
                                  ))
                                  : null}
                              </select>
                              {errorsGame && errorsGame["gameId"] ? (
                                <div className="text-red-600 invalid-feedback">
                                  {errorsGame["gameId"]}
                                </div>
                              ) : null}
                            </div>

                            {/* <div className=" mt-1 shadow-sm">
                              <label class="block text-gray-700 text-base font-bold mb-2">Session</label>
                              <select
                                onChange={inputChangeCreate}
                                name="isOpen"
                                value={fieldsGame && fieldsGame["isOpen"] ? fieldsGame["isOpen"] : ""}
                                className='px-3 py-2 text-sm bg-transparent border border-gray-900 rounded md:w-60 w-full focus:outline-none text-[#495057] placeholder:text-gray-500 block'>
                                <option value="">Select Session</option>
                                <option value={false}>Close</option>
                                <option value={true}>Open</option>
                              </select>
                              {errorsGame && errorsGame["isOpen"] ?
                                <div className="text-red-600 invalid-feedback">
                                  {errorsGame["isOpen"]}
                                </div>
                                : null}
                            </div> */}

                            {/* <div className=" mt-1 w-full shadow-sm">
                              <label class="block text-gray-700 text-base font-bold mb-2">Number</label>
                              <select
                                onChange={inputChangeCreate}
                                name="num"
                                value={fieldsGame && fieldsGame["num"] ? fieldsGame["num"] : ""}
                                className='px-3 py-2 text-sm bg-transparent border border-gray-900 rounded md:w-60 w-full focus:outline-none text-[#495057] placeholder:text-gray-500 block'>
                                <option value="">Select Number</option>
                                {numberArray && numberArray.length > 0 && numberArray.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                              {errorsGame && errorsGame["num"] ?
                                <div className="text-red-600 invalid-feedback">
                                  {errorsGame["num"]}
                                </div>
                                : null}
                            </div> */}
                            <div className="mt-1 w-full shadow-sm">
                              <label className="block text-gray-700 text-base font-bold mb-2">
                                Number
                              </label>
                              <Select
                                onChange={(selectedOption) =>
                                  inputChangeCreate({
                                    target: {
                                      name: "num",
                                      value: selectedOption.value,
                                    },
                                  })
                                }
                                name="num"
                                value={
                                  fieldsGame && fieldsGame["num"]
                                    ? {
                                      value: fieldsGame["num"],
                                      label: fieldsGame["num"],
                                    }
                                    : ""
                                }
                                options={
                                  numberList &&
                                  numberList.length > 0 &&
                                  Array.from(
                                    new Set(numberList
                                      .filter(e => e?.toString()?.length === 3)
                                      .map(e => e.toString())) // Ensures all numbers are strings for comparison
                                  ).map(option => ({
                                    value: option,
                                    label: option,
                                  }))
                                }
                                classNamePrefix="react-select"
                                placeholder="Select Number"
                                className="md:w-60 w-full"
                              />
                              {errorsGame && errorsGame["num"] ? (
                                <div className="text-red-600 invalid-feedback">
                                  {errorsGame["num"]}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="flex justify-start md:justify-end flex-wrap space-y-3 md:space-y-0 mt-2   w-full">
                            <div className=" flex px-3 md:flex-row flex-col gap-3 md:w-fit w-full">
                              <button
                                className="flex-1 justify-center md:w-44 w-full px-4 py-2 text-sm text-white transition duration-150 ease-in-out bg-[#0F9707] border border-[#0F9707] rounded-full xl hover:bg-blue hover:text-white focus:outline-none focus:border-[#0F9707] focus:shadow-outline-yellow active:bg-[#0F9707]"
                                type="button"
                                onClick={(e) => createGameSubmit(e)}
                              >
                                Save
                              </button>

                              <button
                                className="flex-1 justify-center md:w-44 w-full px-4 py-2 text-sm text-white transition duration-150 ease-in-out bg-yellow-600 border border-yellow-600 rounded-full xl hover:bg-blue hover:text-white focus:outline-none focus:border-yellow-600 focus:shadow-outline-yellow active:bg-yellow-600"
                                type="button"
                                onClick={(e) => ShowWinnerList(e)}
                              >
                                Show Winner List
                              </button>

                              <button
                                className="flex justify-center md:w-44  w-full    px-4 py-2 text-sm text-white transition duration-150 ease-in-out bg-pink-600 border border-pink-600 rounded-full xl hover:bg-blue hover:text-white focus:outline-none focus:border-pink-600 focus:shadow-outline-pink active:bg-pink-600"
                                type="button"
                                onClick={(e) => ShowWinnerList2(e)}
                              >
                                Declare Result
                              </button>
                            </div>
                            <div className=" flex  px-3 gap-3">

                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                          <thead className="text-gray-800 capitalize bg-gray-50  ">
                            <tr>
                              <th scope="col" className="px-6 py-3 text-center">
                                S.No.
                              </th>
                              <th scope="col" className="px-6 py-3 text-center">
                                Result Date
                              </th>
                              <th scope="col" className="px-6 py-3 text-center">
                                Game Name
                              </th>
                              <th scope="col" className="px-6 py-3 text-center">
                                Opening Number
                              </th>
                              <th scope="col" className="px-6 py-3 text-center">
                                Open Ank
                              </th>
                              <th scope="col" className="px-6 py-3 text-center">
                                RollBack
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {gameResultList && gameResultList.length > 0
                              ? gameResultList.map((element, index) => (
                                <React.Fragment key={index}>
                                  <tr
                                    key={index}
                                    className={
                                      index % 2 === 0
                                        ? "bg-white border-b  "
                                        : "bg-gray-100  "
                                    }
                                  >
                                    <td
                                      scope="row"
                                      className="px-6 py-4 font-medium text-gray-700 whitespace-nowrap  text-center "
                                    >
                                      {offset + index + 1}
                                    </td>
                                    <td
                                      scope="row"
                                      className="px-6 py-4 font-medium text-gray-700 whitespace-nowrap  text-center "
                                    >
                                      {element && element.rDate
                                        ? new Date(
                                          element.rDate
                                        ).toLocaleDateString()
                                        : "-"}
                                    </td>
                                    <td
                                      scope="row"
                                      className="px-6 py-4 capitalize font-medium text-gray-700 whitespace-nowrap  text-center "
                                    >
                                      {element && element.gameId
                                        ? element.gameId?.name
                                        : "-"}
                                    </td>
                                    <td
                                      scope="row"
                                      className="px-6 py-4 font-medium text-gray-700 whitespace-nowrap  text-center "
                                    >
                                      {element &&
                                        element.openNumber?.toString()
                                        ? element.openNumber?.toString()
                                        : "0"}
                                    </td>
                                    <td
                                      scope="row"
                                      className="px-6 py-4 font-medium text-gray-700 whitespace-nowrap  text-center "
                                    >
                                      {element &&
                                        element.jodiNumber?.toString()
                                        ? element.jodiNumber?.toString()
                                        : "-"}
                                    </td>

                                    <td
                                      scope="row"
                                      className="px-6 py-4 font-medium text-gray-700 whitespace-nowrap  text-center "
                                    >
                                      {element.jodiNumber &&
                                        element.jodiNumber &&
                                        element.jodiNumber.toString()  ? (
                                        <span
                                          className=" text-red-500 cursor-pointer"
                                          onClick={() => rollback(element)}
                                        >
                                          RoleBack
                                        </span>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                  </tr>
                                </React.Fragment>
                              ))
                              : null}
                          </tbody>
                        </table>
                      </div>
                      {gameResultList && gameResultTotal > 10 ? (
                        <ReactPaginate
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={Math.ceil(gameResultTotal / size)}
                          marginPagesDisplayed={0}
                          pageRangeDisplayed={10}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          pageClassName={"page-cls"}
                          activeClassName={"active"}
                          forcePage={currentPage}
                        />
                      ) : null}
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
          <ViewGameModal
            viewModal={viewModal}
            handleViewHideModal={handleViewHideModal}
            winnerList={winnerList}
            ShowWinnerList2={ShowWinnerList2}
          />
        </div>
      </div>
    </>
  );
};

export default DeclareResultFatafat;
