import axios from "axios";
// let backendUrl = `http://localhost:8804/api`;
// let backendUrl = `https://jeeto-47-backend.vercel.app/api`;
let backendUrl = `https://www.jito47.com/api`;
export function authHeader() {
  let user = JSON.parse(window.sessionStorage.getItem("adminuser"));
  if (user && user.token) {
    return { Authorization: "Bearer " + user.token }; 
  } else {
    return {}; 
  }
}
export function logoutFunction() {
  localStorage.removeItem("adminuser");
  window.location.replace("/login");
  window.location.reload();
}

export const headerForPublicAPI = new Headers({
  "Content-Type": "application/json",
});

export const headerForPrivateAPI = new Headers({
  "Content-Type": "application/json",
  Authorization: authHeader().Authorization,
});

// export const headerForPrivateAPI = () => {
//     const token = authHeader()['Authorization']; // Accessing Authorization property of the object returned by authHeader()
//     const headers = new Headers();
//     if (token) {
//         headers.append('Authorization', token); // Adding Authorization header if token exists
//     }
//     headers.append('Content-Type', 'application/json'); // Always add Content-Type
//     return headers;
// }

// export const headerForPrivateAPIFormData = new Headers({
//     'Content-Type': 'multipart/form-data',
//     'Authorization': authHeader().Authorization
// })

export const headerForPrivateMediaAPI = new Headers({
  "Content-Type": "multipart/form-data",
  Authorization: authHeader().Authorization,
});

// export const APIcallFunction = async (credentials) => {
//     // console.log("credentialscredentials",credentials)
//     const requestOptions = {
//         method: credentials.method,
//         headers: credentials.header,
//         body: JSON.stringify(credentials.body)
//     };

//     // https://profitplay-backend.vercel.app/
//     // localhost:8804
//     try {
//         const response = await fetch("http://localhost:8804/api/v1" + credentials.endPoint, requestOptions);

//         const responseData = await handleResponse(response);

//         return {
//             data: responseData.data||responseData.message
//         };
//     } catch (error) {
//         console.error('Error:', error);
//         throw error;
//     }
// };
export const APIcallFunction = async (credentials) => {
  const requestOptions = {
    method: credentials.method,
    headers: credentials.header,
    body: JSON.stringify(credentials.body),
  };
  try {
    const response = await fetch(
      `${backendUrl}/v1${credentials.endPoint}`,
      requestOptions
    );

    const responseData = await handleResponse(response);

    return {
      data: responseData.data,
    };
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const APIcallFunctionForImage = async (credentials) => {
  try {
    const endpointUrl = `${backendUrl}` + credentials.endPoint;
    const headers = {
      Authorization: authHeader().Authorization,
      "Content-Type": "multipart/form-data",
    };
    const response = await axios.post(endpointUrl, credentials.body, {
      headers,
    });

    // const responseData = await handleResponse(response);
    return {
      data: response.data,
    };
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    // console.log(data);

    if (!response.ok) {
      if (response.status === 401) {
        logoutFunction();
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    if (data.error) {
      if (data.code === 3) {
        logoutFunction();
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
