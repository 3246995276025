import React from 'react'

const PaymentModal = ({ handleSubmit, onClose, reason, setReason }) => {
    const handleReasonChange = (e) => {
        const inputValue = e.target.value;

        // Check if input length is less than or equal to 20
        if (inputValue.length <= 25) {
            setReason(inputValue);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75">
            <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
                <h2 className="text-2xl font-semibold mb-4 text-gray-800">Payment Reason</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="reason" className="block text-gray-700 mb-1">Reason:</label>
                        <input
                            id="reason"
                            type="text"
                            value={reason}
                            onChange={handleReasonChange}
                            className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
                            required
                        />
                        <div className="text-right text-gray-500 text-sm mt-1">
                            {reason.length} / 25
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <button
                            type="submit"
                            className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-200"
                        >
                            Submit
                        </button>
                        <button
                            type="button"
                            onClick={onClose}
                            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 transition duration-200"
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default PaymentModal